


import { createSlice } from '@reduxjs/toolkit';

const loadCartItems = () => {
  const storedItems = localStorage.getItem('cartItems');
  return storedItems ? JSON.parse(storedItems) : [];
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: loadCartItems(), 
  },
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
      localStorage.setItem('cartItems', JSON.stringify(state.items)); 
    },

    // removeItem: (state) => { 
    //   state.items.pop();
    //   localStorage.setItem('cartItems', JSON.stringify(state.items)); 
    // },


    removeItem: (state, action) => {
      const indexToRemove = action.payload; // Assuming action.payload contains the index of the item to remove
      state.items.splice(indexToRemove, 1);
      localStorage.setItem('cartItems', JSON.stringify(state.items)); 
    },
    



    
    
    
    

    


    clearCart: (state) => { 
      state.items.length = 0;
      localStorage.removeItem('cartItems'); 
    },
  },
});

export const { addItem, removeItem, clearCart } = cartSlice.actions;

export default cartSlice.reducer;

