import React, { useEffect, useState } from "react";
import MyContext from "./myContext";
import { fireDB } from "../firebase/FirebaseConfig";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";

const MyState = (props) => {

  // Add Product

  const [products, setProducts] = useState({
    title: null,
    price: null,
    oldPrice: null,
    newPrice: null,

    imageUrl: null,
    productImages: [],
    category: null,
    description: null,
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const addProduct = async () => {


    const productRef = collection(fireDB, "products");

    try {
      await addDoc(productRef, products);
      toast.success("Product added successfully");

      // Fetch the product data and wait for it to complete
      await getProductData();

      // Redirect after product is added and data is fetched
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }

    // Reset the form after adding the product
    setProducts({
      title: "",
      price: "",
      productImages: [],
      oldPrice: "",
      newPrice: "",
      imageUrl: "",
      category: "",
      description: "",
    });
  };

  // add onsale products


  const [onSaleProducts, setOnSaleProducts] = useState({
    title: null,
    price: null,
    oldPrice: null,
    newPrice: null,

    imageUrl: null,
    productImages: [],
    category: null,
    description: null,
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const addOnSaleProduct = async () => {



    const onSaleProductRef = collection(fireDB, "onsaleproducts");

    try {
      await addDoc(onSaleProductRef, onSaleProducts);
      toast.success("Product added successfully");

      // Fetch the product data and wait for it to complete
      await getOnSaleProductData();

      // Redirect after product is added and data is fetched
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }

    // Reset the form after adding the product
    setOnSaleProducts({
      title: "",
      price: "",
      productImages: [],
      oldPrice: "",
      newPrice: "",
      imageUrl: "",
      category: "",
      description: "",
    });
  };

  

  //best selling products

  const [bestSaleProducts, setBestSaleProducts] = useState({
    title: null,
    price: null,
    oldPrice: null,
    newPrice: null,

    imageUrl: null,
    productImages: [],
    category: null,
    description: null,
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const addBestSaleProduct = async () => {



    const bestSaleProductRef = collection(fireDB, "bestsaleproducts");

    try {
      await addDoc(bestSaleProductRef, bestSaleProducts);
      toast.success("Product added successfully");

      // Fetch the product data and wait for it to complete
      await getBestSaleProductData();

      // Redirect after product is added and data is fetched
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }

    // Reset the form after adding the product
    setBestSaleProducts({
      title: "",
      price: "",
      productImages: [],
      oldPrice: "",
      newPrice: "",
      imageUrl: "",
      category: "",
      description: "",
    });
  };



  //shop by age products

  
 const [ageProducts, setAgeProducts] = useState({
    title: null,
    price: null,
    oldPrice: null,
    newPrice: null,

    imageUrl: null,
    productImages: [],
    category: null,
    description: null,
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const addAgeProduct = async () => {

 

    const ageProductRef = collection(fireDB, "ageproducts");

    try {
      await addDoc(ageProductRef, ageProducts);
      toast.success("Product added successfully");

      // Fetch the product data and wait for it to complete
      await getAgeProductData();

      // Redirect after product is added and data is fetched
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }

    // Reset the form after adding the product
    setAgeProducts({
      title: "",
      price: "",
      productImages: [],
      oldPrice: "",
      newPrice: "",
      imageUrl: "",
      category: "",
      description: "",
    });
  };




  //shop by category products


  const [categoryProducts, setCategoryProducts] = useState({
    title: null,
    price: null,
    oldPrice: null,
    newPrice: null,

    imageUrl: null,
    productImages: [],
    category: null,
    description: null,
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const addCategoryProduct = async () => {

 

    const categoryProductRef = collection(fireDB, "categoryproducts");

    try {
      await addDoc(categoryProductRef, categoryProducts);
      toast.success("Product added successfully");

      // Fetch the product data and wait for it to complete
      await getCategoryProductData();

      // Redirect after product is added and data is fetched
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }

    // Reset the form after adding the product
    setCategoryProducts({
      title: "",
      price: "",
      productImages: [],
      oldPrice: "",
      newPrice: "",
      imageUrl: "",
      category: "",
      description: "",
    });
  };



  //  get products data from the firebase

  const [product, setProduct] = useState([]);
  const getProductData = async () => {
    try {
      const q = query(collection(fireDB, "products"), orderBy("time"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let productsArray = [];
        QuerySnapshot.forEach((doc) => {
          productsArray.push({ ...doc.data(), id: doc.id });
        });
        setProduct(productsArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };



  const [onSaleProduct, setOnSaleProduct] = useState([]);

  const getOnSaleProductData = async () => {
    try {
      const q = query(collection(fireDB, "onsaleproducts"), orderBy("time"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let onSaleProductsArray = [];
        QuerySnapshot.forEach((doc) => {
          onSaleProductsArray.push({ ...doc.data(), id: doc.id });
        });
        setOnSaleProduct(onSaleProductsArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };


  const [bestSaleProduct, setBestSaleProduct] = useState([]);

  const getBestSaleProductData = async () => {
    try {
      const q = query(collection(fireDB, "bestsaleproducts"), orderBy("time"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let bestSaleProductsArray = [];
        QuerySnapshot.forEach((doc) => {
          bestSaleProductsArray.push({ ...doc.data(), id: doc.id });
        });
        setBestSaleProduct(bestSaleProductsArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };


  const [ageProduct, setAgeProduct] = useState([]);
  const getAgeProductData = async () => {
    try {
      const q = query(collection(fireDB, "ageproducts"), orderBy("time"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let ageProductsArray = [];
        QuerySnapshot.forEach((doc) => {
          ageProductsArray.push({ ...doc.data(), id: doc.id });
        });
        setAgeProduct(ageProductsArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };


  const [categoryProduct, setCategoryProduct] = useState([]);
  const getCategoryProductData = async () => {
    try {
      const q = query(collection(fireDB, "categoryproducts"), orderBy("time"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let categoryProductsArray = [];
        QuerySnapshot.forEach((doc) => {
          categoryProductsArray.push({ ...doc.data(), id: doc.id });
        });
        setCategoryProduct(categoryProductsArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };



  // update product function

  const edithandle = (item) => {
    setProducts(item);
  };

  const updateProduct = async () => {
    try {
      await setDoc(doc(fireDB, "products", products.id), products);
      toast.success("Product Updated successfully");

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 800);

      getProductData();
    } catch (error) {
      console.log(error);
    }
  };
  
  //update  onsale products
  
  
  const editOnSale = (item) => {
    setOnSaleProducts(item);
  };

  const updateOnSaleProduct = async () => {
    try {
      await setDoc(doc(fireDB, "onsaleproducts", onSaleProducts.id), onSaleProducts);
      toast.success("Product Updated successfully");

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 800);

      getOnSaleProductData();
    } catch (error) {
      console.log(error);
    }
  };

  //update Best Selling products


  const editBestSale = (item) => {
    setBestSaleProducts(item);
  };

  const updateBestSaleProduct = async () => {
    try {
      await setDoc(doc(fireDB, "bestsaleproducts", bestSaleProducts.id), bestSaleProducts);
      toast.success("Product Updated successfully");

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 800);

      getBestSaleProductData();
    } catch (error) {
      console.log(error);
    }
  };


  //update shop by age



const editAge = (item) => {
    setAgeProducts(item);
  };

  const updateAgeProduct = async () => {
    try {
      await setDoc(doc(fireDB, "ageproducts", ageProducts.id), ageProducts);
      toast.success("Product Updated successfully");

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 800);

      getAgeProductData();
    } catch (error) {
      console.log(error);
    }
  };



//update shop by caregory


const editCategory = (item) => {
  setCategoryProducts(item);
};

const updateCategoryProduct = async () => {
  try {
    await setDoc(doc(fireDB, "categoryproducts", categoryProducts.id), categoryProducts);
    toast.success("Product Updated successfully");

    setTimeout(() => {
      window.location.href = "/dashboard";
    }, 800);

    getCategoryProductData();
  } catch (error) {
    console.log(error);
  }
};








  //delete product

  const deleteProduct = async (item) => {
    try {
      await deleteDoc(doc(fireDB, "products", item.id));
      toast.success("Product Deleted successfully");

      getProductData();
    } catch (error) {
      toast.success("Product Deleted Falied");
    }
  };



  const deleteOnSaleProduct = async (item) => {
    try {
      await deleteDoc(doc(fireDB, "onsaleproducts", item.id));
      toast.success("Product Deleted successfully");

      getOnSaleProductData();
    } catch (error) {
      toast.success("Product Deleted Falied");
    }
  };


  const deleteBestSaleProduct = async (item) => {
    try {
      await deleteDoc(doc(fireDB, "bestsaleproducts", item.id));
      toast.success("Product Deleted successfully");

      getBestSaleProductData();
    } catch (error) {
      toast.success("Product Deleted Falied");
    }
  };

  const deleteAgeProduct = async (item) => {
    try {
      await deleteDoc(doc(fireDB, "ageproducts", item.id));
      toast.success("Product Deleted successfully");

      getAgeProductData();
    } catch (error) {
      toast.success("Product Deleted Falied");
    }
  };

  const deleteCategoryProduct = async (item) => {
    try {
      await deleteDoc(doc(fireDB, "categoryproducts", item.id));
      toast.success("Product Deleted successfully");

      getCategoryProductData();
    } catch (error) {
      toast.success("Product Deleted Falied");
    }
  };






  //show order

  const [order, setOrder] = useState([]);

  const getOrderData = async () => {
    try {
      const result = await getDocs(collection(fireDB, "order"));
      const ordersArray = [];
      result.forEach((doc) => {
        ordersArray.push(doc.data());
      });
      setOrder(ordersArray);
      console.log(ordersArray);
    } catch (error) {
      console.log(error);
    }
  };





  //show messages on the dashboard

  const [message, setMessage] = useState([]);

  const getMessage = async () => {
    try {
      const result = await getDocs(collection(fireDB, "messages"));
      const messageArray = [];
      result.forEach((doc) => {
        messageArray.push(doc.data());
      });
      setMessage(messageArray);
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    getProductData();
    getOnSaleProductData();
    getBestSaleProductData();
    getAgeProductData();
    getCategoryProductData();

    getOrderData();
    getMessage();
  }, []);


  return (
    <MyContext.Provider
      value={{
        products,
        setProducts,
        addProduct,
        product,


        onSaleProducts,
        setOnSaleProducts,
        addOnSaleProduct,
        onSaleProduct,


        bestSaleProducts,
        setBestSaleProducts,
        addBestSaleProduct,
        bestSaleProduct,


        ageProducts,
        setAgeProducts,
        addAgeProduct,
        ageProduct,


        categoryProducts,
        setCategoryProducts,
        addCategoryProduct,
        categoryProduct,
   


        edithandle,
         updateProduct,

         editOnSale,
         updateOnSaleProduct,

         editBestSale,
         updateBestSaleProduct,

         editAge,
         updateAgeProduct,

         editCategory,
         updateCategoryProduct,


      




        deleteProduct,
        deleteOnSaleProduct,
        deleteBestSaleProduct,
        deleteAgeProduct,
        deleteCategoryProduct,
       






        order,
    
        message,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export default MyState;
