import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addItem } from "../redux/cartSlice"; 
import { useNavigate } from "react-router-dom";


function ProductCard({ product }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

 
  const handleAddToCart = (product) => {
    dispatch(addItem(product));
    toast.success("Added to cart");
  };

  const goToProductDetails = () => {
 
    navigate(`/products/${product.id}`) 
  };

  
  const goToNewProductDetails = () => {
 
    navigate(`/newproducts/${product.id}`) 
  };

  return (
    <div className="bg-[#F9F9F9] p-2 rounded-lg shadow-lg overflow-hidden h-[500px] w-[250px] ">

      <img
      onClick={  goToProductDetails  }
        src={product.image || product.imageUrl}
        alt={product.name}
        className="w-[235px] h-[280px] object-cover rounded-lg cursor-pointer"
      />

      <div className="p-2">
        <h2 className="text-md font-semibold">{product.name || product.title}</h2>
        <h2 className="text-sm ">{product.age + "+ "+"Year" }</h2>
        <div className="py-3">
          <span className="text-lg text-black font-semibold">
            ₹{product.price}
          </span>
          <div className="flex justify-center items-center py-4">
            <button
              onClick={() => handleAddToCart(product)} 
              className="px-10 py-2 bg-[#FC3] text-black rounded-xl"
            >
              Add to Cart
            </button>

           
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
