import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";




import ProductInfo from "./components/products/ProductInfo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/dashboard/Dashboard";
import AddProduct from "./components/dashboard/AddProduct";
import UpdateProduct from "./components/dashboard/UpdateProduct";
import Order from "./components/dashboard/Order";
import AllProducts from "./components/products/AllProducts";
import NewArrivalsPage from "./components/productsCategory/NewArrivalsPage";
import Contact from "./components/pages/Contact";
import Age from "./components/productsCategory/Age";
import Category from "./components/productsCategory/Category";
import About from "./components/pages/About";
import Cart from "./components/cart/Cart";
import Login from "./auth/Login";



import { useContext } from "react";
import myContext from "./components/context/myContext";
import { Navigate } from "react-router-dom";
import Signup from "./auth/Signup";
import SearchResults from "./components/pages/SearchResults";
import BestSale from "./components/productsCategory/BestSale";
import OnSale from "./components/productsCategory/OnSale";
import Modal from "./components/dashboard/Modal";

import ZeroToOne from './components/shop by age/ZeroToOne.jsx'
import OneToTwo from "./components/shop by age/OneToTwo";




import ThreeToFive from "./components/shop by age/ThreeToFive";
import SixToEight from "./components/shop by age/SixToEight";
import NineToEleven from "./components/shop by age/NineToEleven";
import TwelvePlus from "./components/shop by age/TwelvePlus";


import Educational from "./components/shop by category/Educational";
import ActivePlay from "./components/shop by category/ActivePlay";
import Puzzles from "./components/shop by category/Puzzles";
import Art from "./components/shop by category/Art";
import Scroll from "./components/Scroll";
import NewProducts from "./components/new products/NewProducts.jsx";


import NewProductsInfo from "./components/products/NewProductsInfo.jsx";

import AddOnSaleProduct from "./components/dashboard/AddOnSaleProduct.jsx";

import AddBestSaleProduct from "./components/dashboard/AddBestSaleProduct.jsx";

import AddAgeProduct from "./components/dashboard/AddAgeProduct.jsx";

import AddCategoryProduct from "./components/dashboard/AddCategoryProduct.jsx";

import UpdateOnSaleProduct from "./components/dashboard/update product/UpdateOnSaleProduct.jsx";

import UpdateBestSaleProduct from "./components/dashboard/update product/UpdateBestSaleProduct.jsx";

import UpdateAgeProduct from "./components/dashboard/update product/UpdateAgeProduct.jsx";

import UpdateCategoryProduct from "./components/dashboard/update product/UpdateCategoryProduct.jsx";

import Terms from "./components/privacy policy/Terms.jsx";
import Privacy from "./components/privacy policy/Privacy.jsx";
import Refund from "./components/privacy policy/Refund.jsx";
import Thank from "./components/pages/Thank.jsx";





function App() {
  
  const context = useContext(myContext);
  const { product,    onSaleProduct,  bestSaleProduct, ageProduct, categoryProduct } = context;

  const products = {
    product: product,
    onSaleProduct: onSaleProduct,
    bestSaleProduct: bestSaleProduct,
    ageProduct: ageProduct,
    categoryProduct: categoryProduct,
  };

  

  return (
    <Router>

      <Scroll />

      <Navbar {...products} />

     

  <Routes>
        <Route path={"/"} element={<Home />} />

   
       

        <Route path={"/allproducts"} element={<AllProducts />} />

        <Route path={"/contact"} element={<Contact />} />



      {/* Shop by Age */}

        <Route path={"/shop-by-age"} element={<Age  products={ ageProduct}/>} />

        <Route path={"/learning-toys-for-1-year-olds"} element={<ZeroToOne  products={ ageProduct }/> } />

        <Route path={"/learning-toys-for-1-to-2-year-olds"} element={<OneToTwo  products={ageProduct}/>} />


        <Route path={"/learning-toys-for-3-to-5-year-olds"} element={<ThreeToFive  products={ageProduct}/>} />

        <Route path={"/learning-toys-for-6-to-8-year-olds"} element={<SixToEight  products={ageProduct}/>} />

        <Route path={"/learning-toys-for-9-to-11-year-olds"} element={<NineToEleven  products={ageProduct}/>} />

        <Route path={"/learning-toys-for-12-year-olds"} element={<TwelvePlus  products={ageProduct}/>} />



{/* shop by category */}


        <Route path="/shop-by-category" element={<Category products={ categoryProduct} /> } />

        <Route path="/educational-toys-for-kids-online" element={<Educational products={categoryProduct} /> } />

        <Route path="/indoor-active-play-toys-for-kids-online" element={<ActivePlay  products={categoryProduct} /> } />

        <Route path="/puzzle-games-for-kids-online" element={<Puzzles products={categoryProduct} /> } />

        <Route path="/arts-and-crafts-kits-for-kids-online" element={<Art products={categoryProduct} /> } />



{/* privacy policy */}

<Route path="/terms-and conditions" element={<Terms /> } />

<Route path="/privacy-policy" element={<Privacy /> } />

<Route path="/refund-policy" element={<Refund /> } />





        <Route path="/best-selling-toys-and-games-for-kids" element={<BestSale products={  bestSaleProduct } /> } />

        <Route path="/deals-and-discount-of-the-day" element={<OnSale  products={  onSaleProduct  }/> } />

        
        <Route   path={"/new-arrivals-best-toys-for-kids"}  element={<NewArrivalsPage products={product} />}  />


      

        <Route path={"/cart"} element={<Cart products={products }   />} />
       

        
     
        <Route path={"/new"} element={<NewProducts products={product }   />} />

     

        <Route path={"/about"} element={<About />} />



        <Route path="/newproducts/:id" element={<NewProductsInfo  />} />

      

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/search-results" element={<SearchResults />} />

        <Route path="/thanks" element={<Thank />} />

        <Route path="/modal" element={<Modal />} />

        <Route
          path="/order"
          element={
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRouteForAdmin>
              <Dashboard products={products} />
            </ProtectedRouteForAdmin>
          }
        />

        <Route
          path="/addproduct"
          element={
            <ProtectedRouteForAdmin>
              <AddProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/addonsaleproduct"
          element={
            <ProtectedRouteForAdmin>
              <AddOnSaleProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/addbestsaleproduct"
          element={
            <ProtectedRouteForAdmin>
              <AddBestSaleProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/addageproduct"
          element={
            <ProtectedRouteForAdmin>
              <AddAgeProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/addcategoryproduct"
          element={
            <ProtectedRouteForAdmin>
              <AddCategoryProduct />
            </ProtectedRouteForAdmin>
          }
        />



      







        <Route
          path="/updateproduct"
          element={
            <ProtectedRouteForAdmin>
              <UpdateProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/updateonsaleproduct"
          element={
            <ProtectedRouteForAdmin>
              <UpdateOnSaleProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/updatebestsaleproduct"
          element={
            <ProtectedRouteForAdmin>
              <UpdateBestSaleProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/updateageproduct"
          element={
            <ProtectedRouteForAdmin>
              <UpdateAgeProduct />
            </ProtectedRouteForAdmin>
          }
        />

<Route
          path="/updatecategoryproduct"
          element={
            <ProtectedRouteForAdmin>
              <UpdateCategoryProduct />
            </ProtectedRouteForAdmin>
          }
        />






      </Routes>

      <ToastContainer />
      <Footer />
    </Router>
  );
}

export default App;

//protected route for user

export const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("user");
  if (user) {
    return children;
  } else {
    return <Navigate to={"/login"} />;
  }
};

export const ProtectedRouteForAdmin = ({ children }) => {
  const admin = JSON.parse(localStorage.getItem("user"));

  if (admin && admin.user && admin.user.email === "admin@anshitoys.com") {
    return children;
  } else {
    return <Navigate to={"/login"} />;
  }
};
