import React from "react";
import { useContext } from "react";
import myContext from "./context/myContext";

import { useNavigate } from "react-router-dom";
import HotProductCard from './products/HotProductCard'


const HotDeals = () => {

  const context = useContext(myContext);
  const { product,    onSaleProduct,  bestSaleProduct, ageProduct, categoryProduct } = context;



  const navigate = useNavigate();

  const threeProducts = onSaleProduct.slice(0, 3);

  return (


  <div className="bg-[#D74B4B]">


    <div className=" px-8 xl:flex-row  xl:gap-12 2xl:py-36 lg:py-20 py-18  flex flex-col xl:justify-between 2xl:flex-row  lg:flex-col  lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto  ">


      <div className="flex-col justify-between items-start py-8  ">
        <h3 className="lg:text-[50px] leading-[50px]  text-3xl pb-2 text-white xl:text-left  text-center font-semibold">
          Hot Deals!
        </h3>
        <p className="lg:text-[25px] lg:text-md text-lg text-white xl:text-left  text-center py-2 ">
        Unmissable Savings on Educational Toys!
        </p>

        <div className=" py-8 xl:pr-60 ">
          <button onClick={ () => navigate('/shop-by-category')}  className="bg-[#FC3] w-full sm:w-48 sm:px-4 sm:py-2 sm:flex sm:justify-center sm:mx-auto 2xl:px-8 px-24 py-4 2xl:py-2 rounded-lg text-[20px] font-semibold ">
            VIEW ALL
          </button>
        </div>

      </div>




      <div className="flex    lg:flex-row flex-col  gap-4  py-8  justify-center items-center  ">
      {threeProducts.map((product) => (
        

          <HotProductCard product={product} />
        ))}
      </div>
    </div>


    </div>
  );
};

export default HotDeals;
