import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

const Thank = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[600px]">
      <h1 className="text-4xl md:text-6xl font-bold text-center mb-8">Thank you for shopping with us!</h1>
      <Link to="/" className="bg-[#FC3] text-white px-6 py-3 rounded-md shadow-md  transition duration-300 ease-in-out">Go to Home</Link>
    </div>
  );
};

export default Thank;
