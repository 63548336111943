import React, { useContext } from 'react'
import myContext from '../context/myContext'
import { useState } from 'react';

function UpdateProduct() {
    const context = useContext(myContext);
    const { products, setProducts, updateProduct } = context;

  


  

    return (
        <div className=''>
            <div className=' flex justify-center items-center  my-16'>
                <div className=' bg-[#FC3] px-10 py-10 rounded-xl  '>
                    <div className="">
                        <h1 className='text-center  text-xl mb-4 font-bold'>Update Product</h1>
                    </div>
                    <div>
                        <input type="text"
                            value={products.title}
                            onChange={(e) => setProducts({ ...products, title: e.target.value })}
                            name='title'
                            className=' bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg  placeholder:text-gray-700 outline-none'
                            placeholder='Update Product Name'
                        />
                    </div>

                    <div>
                        <input type="number"
                            value={products.price}
                            onChange={(e) => setProducts({ ...products, price: e.target.value })}
                            name='price'
                            className=' bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg  placeholder:text-gray-700 outline-none'
                            placeholder='Update Product MRP'
                        />
                    </div>

                    <div>
                        <input type="number"
                            value={products.oldPrice}
                            onChange={(e) => setProducts({ ...products, oldPrice: e.target.value })}
                            name='oldprice'
                            className=' bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg  placeholder:text-gray-700 outline-none'
                            placeholder='Update Product Old Price'
                        />
                    </div>

                    <div>
                        <input type="number"
                            value={products.newPrice}
                            onChange={(e) => setProducts({ ...products, newPrice: e.target.value })}
                            name='newprice'
                            className=' bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg  placeholder:text-gray-700 outline-none'
                            placeholder='Update Product New Price'
                        />
                    </div>



                    <div>
                        <input type="text"
                            value={products.imageUrl}
                            onChange={(e) => setProducts({ ...products, imageUrl: e.target.value })}
                            name='imageurl'
                            className=' bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg  placeholder:text-gray-700 outline-none'
                            placeholder='Update Product Main Image'
                        />
                    </div>


                    <div>
                        <input type="text"
                            value={products.videoUrl}
                            onChange={(e) => setProducts({ ...products, videoUrl: e.target.value })}
                            name='videourl'
                            className=' bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg  placeholder:text-gray-700 outline-none'
                            placeholder='Update Product Video Url'
                        />
                    </div>
           

<div>
  <select
    name="category"
    onChange={(e) => setProducts({ ...products, category: e.target.value })}
    value={products.category}
    className="bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg placeholder:text-gray-700 outline-none"
  >
    <option value="" disabled selected hidden>
      Select Product Category
    </option>
    <option value="Educational">Educational</option>
    <option value="Active Play">Active Play</option>
    <option value="Puzzles">Puzzles</option>
    <option value="Arts & Crafts">Arts & Crafts</option>
  </select>
</div>


<div>
  <select
    name="stock"
    onChange={(e) => setProducts({ ...products, stock: e.target.value })}
    value={products.stock}
    className="bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg placeholder:text-gray-700 outline-none"
  >
    <option value="" disabled selected hidden>
     Out of Stock
    </option>
    <option value="Out of Stock!">Yes</option>
    <option value="">No</option>

  </select>
</div>





<div>
  <select
    name="age"
    onChange={(e) => setProducts({ ...products, age: e.target.value })}
    value={products.age}
    className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-gray-800 outline-none"
  >
    <option value="" disabled hidden>
      Select Product Age Category
    </option>
    <option value="0-1">0-1 years</option>
    <option value="1-2">1-2 years</option>
    <option value="3-5">3-5 years</option>
    <option value="6-8">6-8 years</option>
    <option value="9-11">9-11 years</option>
    <option value="12+">12+ years</option>
  </select>
</div>




    <div className="flex flex-col">
            {products.productImages.map((imageUrl, index) => (
              <input
                key={index}
                type="text"

                onChange={(e) => {
                  const updatedImages = [...products.productImages];
                  updatedImages[index] = e.target.value;
                  setProducts({ ...products, productImages: updatedImages });
                }}

            
                value={imageUrl}
                className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-gray-800 outline-none"
                placeholder="Update Product Images URL"
              />
            ))}
       
          </div>


          <div>
                        <textarea cols="30" rows="10" name='title'
                         value={products.description}
                         onChange={(e) => setProducts({ ...products, description: e.target.value })}
                            className=' bg-white mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg  placeholder:text-gray-700 outline-none'
                            placeholder='Update Product descripion'>

                        </textarea>
                    </div>



                    <div className=' flex justify-center mb-3'>
                        <button
                        onClick={updateProduct}
                            className=' bg-yellow-500 w-full text-black font-bold  px-2 py-2 rounded-lg'>
                            Update Product
                        </button>
                    </div>
                 
                </div>
            </div>
        </div>
    )
}

export default UpdateProduct