
import React from 'react';
import { Link } from 'react-router-dom';

import image1 from '../assets/1.png'
import image2 from '../assets/2.png'
import image3 from '../assets/3.png'
import image4 from '../assets/4.png'
import image5 from '../assets/5.png'
import image6 from '../assets/6.png'

const ShopByAge = () => {

  return (

    <div className=" pt-8   lg:px-2   lg:-mt-24 2xl:my-4 lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto ">
        <h2 className="lg:text-[64px] text-3xl font-semibold   text-gray-800 text-center mb-12 lg:mt-28 lg:py-8 2xl:mt-0 ">Shop By Age</h2>
  
    <div className=" flex flex-row flex-wrap justify-center gap-4 ">
    
    <Link    to={'/learning-toys-for-1-year-olds'} >
    <div className='w-[200px] xl:w-[345px] mx-auto 2xl:h-[200px]  rounded-2xl '>
    <img className='object-cover lg:object-cover' src={image1} alt="zero-to-one-year" />

    </div>
    </Link>
  
    <Link    to={'/learning-toys-for-1-to-2-year-olds'} >
    <div className='w-[200px] xl:w-[345px] xl:h-[200px]  rounded-2xl  '>
    <img className='object-cover lg:object-cover' src={image2} alt="one-to-two-year" />
    </div>
    </Link>


    <Link    to={'/learning-toys-for-3-to-5-year-olds'} >
    <div className='w-[200px] xl:w-[345px] xl:h-[200px]  rounded-2xl '>
    <img className='object-cover lg:object-cover' src={image3} alt="three-to-five-year" />

    </div>
    </Link>

    <Link    to={'/learning-toys-for-6-to-8-year-olds'} >
    <div className='w-[200px] xl:w-[345px] xl:h-[200px]  rounded-2xl '>
    <img className='object-cover lg:object-cover' src={image4} alt="six-to-eight-year" />
    </div>
    </Link>


    <Link    to={'/learning-toys-for-9-to-11-year-olds'} >
    <div className= 'w-[200px] xl:w-[345px] xl:h-[200px]  rounded-2xl '>
    <img className='object-cover lg:object-cover' src={image5} alt="nine-to-eleven-year" />

    </div>
    </Link>

    <Link    to={'/learning-toys-for-12-year-olds'} >
    <div className='w-[200px] xl:w-[345px] xl:h-[200px]  rounded-2xl '>
    <img className='object-cover lg:object-cover' src={image6} alt="twelve-plus-year" />

    </div>
    </Link>
       
     
  
    </div>

    <div className="flex justify-center items-center my-8 ">
    <Link    to={'/shop-by-age'} >     <button className="bg-[#FC3] 2xl:px-8 px-28 py-4 2xl:py-2 rounded-lg text-[20px] font-semibold">
          VIEW ALL
        </button>      </Link>
      </div>



  </div>
  );
};

export default ShopByAge ;





