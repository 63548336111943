import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";





const firebaseConfig = {
  apiKey:'AIzaSyDyulsGwA0qz2uT_xzoy0WPoSgwylPR5xk',
//  apiKey:process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "anshit-toys.firebaseapp.com",
  projectId: "anshit-toys",
  storageBucket: "anshit-toys.appspot.com",
  messagingSenderId: "29653553258",
  appId: "1:29653553258:web:4445489f831d8c6667fa13"
};


const app = initializeApp(firebaseConfig);
const fireDB = getFirestore(app)
const auth = getAuth(app)
export {fireDB, auth}



