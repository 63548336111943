import React from 'react'
import Header from './Header'


import ShopByAge from './ShopByAge'
import ShopByCategory from './ShopByCategory'

import Recommended from './Recommended'
import HotDeals from './HotDeals'
import NewArrival from './NewArrival'
import Testimonials from './Testimonials'








const Home = () => {

  return (


  
    <div className='overflow-x-hidden '>

<Header />
<ShopByAge />
<ShopByCategory />

<HotDeals />
<NewArrival />

<Testimonials />





    </div>
  )
}

export default Home