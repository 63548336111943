import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#FFCC33] py-12 px-8 ">
      <div className="flex flex-col lg:flex-row  2xl:flex-row  2xl:items-start  2xl:space-x-36  justify-between lg:max-w-[900px]  xl:max-w-[1000px] lg:container 2xl:max-w-[1100px] lg:mx-auto">
        <div>
          <img className=" w-36 lg:w-48 h-auto" src={logo} alt="" />
          <ul>
       <Link to={'/'}>    <li className="py-1 text-lg">Home</li> </Link> 
       <Link to={'/shop-by-category'}>      <li className="py-1 text-lg">Category</li> </Link> 
       <Link to={'/shop-by-age'}>      <li className="py-1 text-lg">Age</li>  </Link> 
       <Link to={'/best-selling-toys-and-games-for-kids'}>    <li className="py-1 text-lg">Best Selling</li>  </Link> 
       <Link to={'/deals-and-discount-of-the-day'}>     <li className="py-1 text-lg">On Sale</li>  </Link> 
       <Link to={'/new-arrivals-best-toys-for-kids'}>     <li className="py-1 text-lg">New Arrivals</li>  </Link> 
          </ul>
        </div>

        <div>
          <ul className="md:my-24 my-10">
            <Link to={"/about"}>
              {" "}
              <li className="py-1 text-lg">About Us</li>{" "}
            </Link>

            <Link to={"/contact"}>
              <li className="py-1 text-lg">Contact Us</li>{" "}
            </Link>

            <Link to={"/terms-and conditions"}>
              <li className="py-1 text-lg">Terms and Conditions</li>{" "}
            </Link>

            <Link to={"/privacy-policy"}>
              <li className="py-1 text-lg">Privacy Policy</li>{" "}
            </Link>

            <Link to={"/refund-policy"}>
              <li className="py-1 text-lg">Refund Policy</li>{" "}
            </Link>
          </ul>
        </div>

        <div className="2xl:my-20">
          <p className="font-bold text-black pb-2">Follow Us On Social Media</p>
          <div className="flex space-x-4">
       <Link to={'https://www.facebook.com/profile.php?id=100068515771343'}  target="_blank">    <FaFacebook size={26} /> </Link> 
     <Link to={'https://www.instagram.com/anshit_educational_toys/'}  target="_blank">      <FaInstagram size={26} /> </Link> 
       
       
  <Link to={'https://www.youtube.com/@itika18'}  target="_blank">  <FaYoutube size={26} />  </Link> 
      
   
          </div>
        </div>
      </div>

      <div className="pt-8 flex justify-center">
  <p>
    Designed & Developed by{' '}
    <span className="font-semibold">
      <a href="https://www.papermoon.in/" target="_blank" rel="noopener noreferrer">
        Papermoon
      </a>
    </span>
  </p>
</div>


  
    </footer>
  );
};

export default Footer;
