import React from "react";
import about from "./about.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="px-4 md:px-0">
      <h3 className="text-xl xl:text-[64px] mt-10 xl:mt-20 text-center pb-4 2xl:mt-28">About Us</h3>
      <p className="text-[16px] xl:text-[24px] xl:pt-10    md:w-4/5 xl:w-3/4 mx-auto mb-10 xl:mb-20 text-justify xl:text-center">
        Welcome to AnshitToys, your premier online destination for children's toys in India. We curate a wide-ranging collection of educational, stylish, and unique toys designed to captivate young minds.
      </p>

      <div className="flex flex-col md:flex-row justify-center md:justify-between gap-4 xl:gap-6 bg-[#22A35A] py-8 xl:py-20 mb-10 xl:mb-20 px-4 md:px-10 lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto">
        <div className="w-full md:w-1/2">
          <h2 className="text-[22px] xl:text-[35px]  text-white md:text-left font-semibold mb-2 ml-4">Our Story: Where Learning Meets Play</h2>
          <p className="text-white text-md xl:text-[30px] md:text-md lg:px-4 py-4 px-4 text-justify">
            Once upon a time, in a world filled with boundless curiosity and endless questions, a spark was ignited. That spark, the desire to make learning an enchanting journey, gave birth to AnshitToys. We're not just an eCommerce store; we're a community of parents, educators, and dreamers who believe in the magic of childhood.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img className="w-full h-auto py-4 md:py-0 object-cover" src={about} alt="about" />
        </div>
      </div>

      {/* Our story */}
      <section className="px-4 lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto">

        <div className="flex flex-col  flex-wrap">

      
        <div className="lg:py-10 bg-about1">
          <div className="w-full xl:w-[773px]">
            <h3 className="text-2xl lg:text-[45px] font-semibold">Our Mission and Values</h3>
            <p className="text-md xl:text-[35px] text-justify pt-10">
              Our mission is clear: to offer a thoughtfully curated selection of affordable learning & educational toys for 0 to 12-year-olds that foster curiosity, spark creativity, and nurture young minds. We believe that the early years of a child's life are the most critical for their development, and our commitment to providing enriching yet budget-friendly toys is unwavering.
            </p>
          </div>
        </div>

        <div className="py-12 lg:py-10 bg-about2 ml-0 xl:ml-[300px] 2xl:ml-[450px]   justify-items-center">
          <div className="w-full xl:w-[773px]">
            <h3 className="text-xl lg:text-[45px] font-semibold text-right">Your Journey Begins Here</h3>
            <p className="text-md xl:text-[35px] text-justify pt-10">
              We're dedicated to your satisfaction. Our commitment extends to outstanding customer service, fast shipping, and a hassle-free return policy. When you choose AnshitToys, you're not just choosing toys; you're choosing a partner in your child's educational journey.
            </p>
          </div>
        </div>

        </div>






        <div className="flex flex-col items-center justify-center py-16">
          <p className="text-xl md:text-center lg:text-[45px] lg:leading-[60px] lg:px-4 py-4  md:text-md text-justify xl:text-center">
            Thank you for taking the time to get to know us. We invite you to explore our collection of educational toys for kids.
          </p>
          <Link to="/category">
            <button className="py-4 px-10 bg-[#FC3] rounded-lg text-md font-semibold xl:mt-4">SHOP NOW</button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default About;
