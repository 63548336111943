import { useParams } from "react-router-dom";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { addItem, removeItem, clearCart } from "../redux/cartSlice";

import RelatedProducts from "../RelatedProducts";
import RecentlyProducts from "../RecentlyProducts";
import ProductSlider from "./ProductSlider";

import myContext from "../context/myContext";

const NewProductsInfo = () => {
  const { id } = useParams();

  const context = useContext(myContext);
  const {
    product,
    onSaleProduct,
    bestSaleProduct,
    ageProduct,
    categoryProduct,
  } = context;

  // const products = product.find( (p) => p.id == id )

  const products =
    product.find((p) => p.id === id) ||
    onSaleProduct.find((p) => p.id === id) ||
    bestSaleProduct.find((p) => p.id === id) ||
    ageProduct.find((p) => p.id === id) ||
    categoryProduct.find((p) => p.id === id);

  const dispatch = useDispatch();

  const handleAddToCart = (product) => {
    dispatch(addItem(product));
    toast.success("Added to cart");
  };

  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const video = () => {
    setIsVideoVisible(true);
  };

  if (!products) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#FC3]"></div>
        <div className="text-gray-600 text-2xl ml-4">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className=" px-4 2xl:py-32 py-8 2xl:max-w-[1300px] container mx-auto">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-2  3xl:justify-between 3xl:space-x-[300px] items-start  w-full ">
          <div className=" lg:w-1/2 2xl:py-8">
            <ProductSlider images={products.productImages} />

            <div>
              <div className="py-4 max-w-[200px] container mx-auto">
                <button
                  onClick={video}
                  className="bg-yellow-500 text-white rounded-lg py-2 px-6 font-semibold"
                >
                  Check video
                </button>
              </div>

              {isVideoVisible && (
                <div className="fixed top-0 left-0 w-screen h-screen object-cover flex items-center justify-center bg-black bg-opacity-80 z-50">
                  <div className="relative ">
                    <img
                      className="absolute lg:top-10 w-16 lg:left-10 text-white text-xl cursor-pointer"
                      onClick={() => setIsVideoVisible(false)}
                      src="https://cdn-icons-png.flaticon.com/128/5773/5773168.png"
                      alt=""
                    />

                    {/* Use the YouTube embed code here */}

                    {/* <iframe
                      width="700"
                      height="600"
                      src={products.videoUrl}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe> */}


<div className="iframe-container w-full">
      <iframe
        className="w-full h-[500px] sm:w-[450px] lg:w-[600px] xl:w-[750px] xl:h-[650px]"
        src={products.videoUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        
      ></iframe>
    </div>

                  



                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="lg:w-1/2  2xl:w-[435px]">
            <h2 className="2xl:text-[30px] text-2xl py-2">{products.title} </h2>

            <div className="flex">
              <div class="flex items-center space-x-1">
                <svg
                  class="w-4 h-4 text-yellow-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-yellow-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-gray-300 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </div>
            </div>

            <div className="pt-4">
              {!products.oldPrice && !products.newPrice && (
                <span className="text-3xl  text-black font-semibold">
                  ₹{products.price}
                </span>
              )}
            </div>

            <div className="flex py-2">
              {/* <span className="text-black line-through mr-2 py-1 md:text-[30px] text-lg">
          ₹{products.oldPrice} 
          </span>
          <span className="text-green-500 py-1 md:text-[30px] text-lg">
          ₹{products.newPrice}  
          </span> */}

              {!products.price && (
                <>
                  {products.oldPrice && (
                    <span className="text-black line-through mr-2 py-1 md:text-[30px] text-lg">
                      ₹{products.oldPrice}
                    </span>
                  )}
                  {products.newPrice && (
                    <span className="text-green-500 py-1 md:text-[30px] text-lg">
                      ₹{products.newPrice}
                    </span>
                  )}
                </>
              )}
            </div>

            {products.stock ? (
              <span className="text-2xl  font-semibold text-red-600">
                {products.stock}
              </span>
            ) : null}

            <p className="text-md py-2">Inclusive of all taxes</p>

            {/* <p className="2xl:text-xl text-lg text-justify py-2 leading-8" >
              {products.description}{" "}
            </p> */}

<div className="2xl:text-xl text-lg text-justify py-2 leading-8">
  {products.description.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ))}
</div>


            {/* <div className="flex justify-center items-center py-6  ">
      <button  
        onClick={() => handleAddToCart(products)} 
      className="bg-[#FC3] px-8 py-2 rounded-lg text-[20px] font-semibold w-full">
      Add to Cart
        </button>
      </div> */}

            {products.stock ? null : (
              <div className="flex justify-center items-center py-6 ">
                <button
                  onClick={() => handleAddToCart(product)}
                  className="px-8 py-2 bg-[#FC3] text-black   rounded-lg font-semibold w-full"
                >
                  Add to Cart
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="px-36 border-b-2 border-gray-300"></div>

        <div className="px-36 border-b-2 border-gray-300"></div>

        {/* related Products */}

        <RelatedProducts />

        <div className="px-36 border-b-2 border-gray-300 mb-8"></div>

        {/* recently viewed */}
        <RecentlyProducts />
      </div>
    </>
  );
};

export default NewProductsInfo;
