import { Link, useNavigate } from 'react-router-dom'
import myContext from '../components/context/myContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { auth } from '../components/firebase/FirebaseConfig'
import { signInWithEmailAndPassword } from 'firebase/auth';


function Login() {

    const user = JSON.parse(localStorage.getItem("user"));

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate= useNavigate();

    const login = async () => {

    try {
        const result = await signInWithEmailAndPassword(auth, email, password)
       
        localStorage.setItem('user',JSON.stringify(result));

        toast.success('Signin Successfully');

        navigate('/')

    

    

      } catch (error) {
        toast.error('Sigin Failed');
      
      }
    }
   
    return (
        <div className='px-8  flex justify-center items-center h-[600px] '>
        
     
            <div className='  px-20 py-28 rounded-lg border-2 border-gray-300 shadow-lg'>

                <div>
                    <h1 className='text-center text-black text-xl mb-4 font-bold'> Admin Login</h1>
                </div>
                <div>
                    <input type="email"
                        name='email'
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        className=' border border-black mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-black outline-none'
                        placeholder='Email'
                    />
                </div>
                <div>
                    <input
                        type="password"
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        className=' border border-black mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-black outline-none'
                        placeholder='Password'
                    />
                </div>
                <div className=' flex justify-center mb-3'>
                    <button
                    onClick={login}
                        className=' bg-[#FC3] w-full text-black font-bold  px-2 py-2 rounded-lg'>
                        Login
                    </button>
                </div>
                {/* <div>
                    <h2 className='text-black'>Don't have an account <Link className=' text-blue-500 font-bold' to={'/signup'}>Signup</Link></h2>
                </div> */}
            </div>
        </div>
    )
}

export default Login