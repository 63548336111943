import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import appStore from './components/redux/store'; 
import MyState from './components/context/myState';
import {BrowserRouter as Router} from 'react-router-dom';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <Provider store={appStore}>
    <MyState>
     
      <App />
 
   
    </MyState>
  </Provider>
  
);
