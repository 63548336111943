import React, { useContext, useState, useEffect } from 'react';
import myContext from '../context/myContext';

function AddCategoryProduct() {

  const context = useContext(myContext);
  
  const {  categoryProducts, setCategoryProducts,    addCategoryProduct } = context;

  const [localProducts, setLocalProducts] = useState({ ... categoryProducts });

  const handleInputChange = (e, field) => {
    const updatedProducts = { ...localProducts, [field]: e.target.value };
    setLocalProducts(updatedProducts);
  };

  const handleImageInput = (e, index) => {
    const newProductImages = [...localProducts.productImages];
    newProductImages[index] = e.target.value;
    const updatedProducts = { ...localProducts, productImages: newProductImages };
    setLocalProducts(updatedProducts);
  };

  const addImageInput = () => {
    if (localProducts.productImages.length < 4) {
      const updatedProductImages = [...localProducts.productImages, ""];
      const updatedProducts = { ...localProducts, productImages: updatedProductImages };
      setLocalProducts(updatedProducts);
    }
  };

  useEffect(() => {
    setCategoryProducts(localProducts);
  }, [localProducts, setCategoryProducts]);


  
  return (
    <div className="my-16">
      <div className="flex justify-center items-center ">
        <div className="bg-[#FCE68D] px-10 py-10 rounded-xl">
          <div className="">
            <h1 className="text-center text-black text-2xl pb-4 font-bold">
              Add  Product in Shop By Category 
            </h1>
          </div>
          <div>
            <input
              type="text"
              onChange={(e) => handleInputChange(e, "title")}
              value={categoryProducts.title}
              name="title"
              className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-blaxk placeholder:text-gray-800 outline-none"
              placeholder="Product Title"
            />
          </div>
          <div>
            <input
              type="number"
              name="price"
              onChange={(e) => handleInputChange(e, "price")}
              value={categoryProducts.price}
              className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-blaxk placeholder:text-gray-800 outline-none"
              placeholder="Product MRP"
            />
          </div>
          <div>
            <input
              type="number"
              name="oldprice"
              onChange={(e) => handleInputChange(e, "oldPrice")}
              value={categoryProducts.oldPrice}
              className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-blaxk placeholder:text-gray-800 outline-none"
              placeholder="Product Old Price"
            />
          </div>
          <div>
            <input
              type="number"
              name="newprice"
              onChange={(e) => handleInputChange(e, "newPrice")}
              value={categoryProducts.newPrice}
              className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-blaxk placeholder:text-gray-800 outline-none"
              placeholder="Product New Price"
            />
          </div>
          <div>
            <input
              type="text"
              name="imageurl"
              onChange={(e) => handleInputChange(e, "imageUrl")}
              value={categoryProducts.imageUrl}
              className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-blaxk placeholder:text-gray-800 outline-none"
              placeholder="Product Image URL"
            />
          </div>


          <div>
            <input
              type="text"
              name="videourl"
              onChange={(e) => handleInputChange(e, "videoUrl")}
              value={categoryProducts.videoUrl}
              className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-blaxk placeholder:text-gray-800 outline-none"
              placeholder="Product Video URL"
            />
          </div>


 
          <div>
  <select
    name="category"
    onChange={(e) => handleInputChange(e, "category")}
    value={categoryProducts.category}
    className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-gray-800 outline-none"
  >
    <option value="" disabled selected hidden>
      Select Product Category
    </option>
    <option value="Educational">Educational</option>
    <option value="Active Play">Active Play</option>
    <option value="Puzzles">Puzzles</option>
    <option value="Arts">Arts & Crafts</option>

  </select>
</div>


<div>
  <select
    name="stock"
    onChange={(e) => handleInputChange(e, "stock")}
    value={categoryProducts.stock}
    className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-gray-800 outline-none"
  >
    <option value="" disabled selected hidden>
     Out of Stock
    </option>
    <option value="Out of Stock!">Yes</option>
    <option value="">No</option>


  </select>
</div>


          <div>

          <div>
  <select
    name="age"
    onChange={(e) => handleInputChange(e, "age")}
    value={categoryProducts.age}
    className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-gray-800 outline-none"
  >
    <option value="" disabled selected hidden>
      Select Product Age Category
    </option>
    <option value="0-1">0-1 years</option>
  <option value="1-2">1-2 years</option>
  <option value="3-5">3-5 years</option>
  <option value="6-8">6-8 years</option>
  <option value="9-11">9-11 years</option>
  <option value="12+">12+ years</option>
  </select>
</div>


            <textarea
              cols="30"
              rows="10"
              name="description"
              onChange={(e) => handleInputChange(e, "description")}
              value={categoryProducts.description}
              className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-blaxk placeholder:text-gray-800 outline-none"
              placeholder="Product Description"
            ></textarea>
          </div>


          <div className="flex flex-col">
            {categoryProducts.productImages.map((imageUrl, index) => (
              <input
                key={index}
                type="text"
                onChange={(e) => handleImageInput(e, index)}
                value={imageUrl}
                className="bg-gray-200 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-black placeholder:text-gray-800 outline-none"
                placeholder="Add Product Image URL"
              />
            ))}
            <button
              onClick={addImageInput}
              className="my-4 px-4 py-2 bg-[#FC3] rounded-lg text-black font-bold hover:bg-yellow-600"
            >
              Add Products Multiple Image URL
            </button>
          </div>


       

       



          <div className="flex justify-center mb-3">
            <button
              onClick={  addCategoryProduct}
              className="bg-[#FC3] w-full text-black font-bold  px-2 py-2 rounded-lg"
            >
              Add Product
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategoryProduct;
