import React from 'react'

const Refund = () => {
  return (
    <div className='my-8 container mx-auto max-w-[350px] sm:max-w-[600px] md:max-w-[700px]  lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto  items-center xl:px-4    '>
    <h1 class="text-3xl font-bold text-center py-8">Refund , Cancellation and Shipping </h1>



<p class="text-lg leading-7 text-gray-700 mb-6 text-justify">
Thank you for shopping with AnshiToys.com. We strive to provide you with the best quality products and a seamless shopping experience. Please review our Refund and Cancellation Policy carefully before making a purchase.

<br />Shipping Time Period:
Once an order is placed on AnshiToys.com,It will Take around 10-15 Days from the date of Comformation of Booking.

<br />Cancellation Policy:
Once an order is placed on AnshiToys.com, it cannot be canceled or modified. Please ensure that you review your order thoroughly before confirming your purchase.

<br />Refund Policy:
Refunds are not available. We do not offer refunds for any products purchased on our website. However, we do provide a replacement for items that are received in a damaged or defective condition.

<br />Replacement Policy:
If you receive a product that is broken or if the package is disrupted, we offer a replacement for the affected item(s). To be eligible for a replacement, you must:

<br />Contact Us: Notify us of the issue within within 48 hours  of receiving the product. You can contact our customer service team at [customer service email or phone number].

<br />Provide Proof: To initiate the replacement process, you must provide solid proof of the issue. This includes videography of the unboxing process clearly showing the condition of the product and packaging at the time of delivery.

<br />Submit Request: Submit your replacement request along with the proof to [email address for replacement requests]. Please include your order number, a detailed description of the issue, and the attached videography.

<br />Approval: Our team will review your request and proof. If approved, we will provide you with instructions on how to return the damaged item.

Return of Item: Once you receive approval, you may be required to return the damaged item. Follow the provided instructions for the return process.

<br />Replacement: Upon receiving the returned item and verifying the issue, we will ship a replacement product to you at no additional cost.

<br />Contact Information
If you have any questions or concerns about our Refund and Cancellation Policy, please contact our customer service team at [customer service email or phone number].

Please note that AnshiToys.com reserves the right to update or modify this policy at any time without prior notice. It is your responsibility to review this policy periodically for changes.

Shipping Charges are calculated as 80 Rupees per Kg, which is included in the MRP of the Product <br />

Thank you for choosing AnshiToys.com. We appreciate your understanding and cooperation.

</p>
  </div>
  )
}

export default Refund