import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import { Link } from 'react-router-dom';

import banner1 from '../assets/banner-1.png'
import banner2 from '../assets/banner-2.png'
import banner3 from '../assets/banner-3.png'

const Header = () => {
  return (

    <div className=" w-full ">

      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        stopOnHover={false}
        transitionTime={500}
        interval={3000}
        
        
        
      >

<Link to={'/indoor-active-play-toys-for-kids-online'}>    <div>

<img
    src={banner1}
    alt="Image 1"
    className="cursor-pointer object-contain lg:object-cover w-screen md:h-[500px] xl:h-screen  "
  />  
</div>
</Link> 

<Link to={'/category'}>  

        <div>
          <img
     src={banner2}
            alt="Image 2"
            className="cursor-pointer object-contain lg:object-cover  w-screen md:h-[500px]  xl:h-screen "
          />
        </div>
        </Link> 



        <Link to={'/category'}>  
        <div>
          <img
             src={banner3}
            alt="Image 3"
            className="cursor-pointer object-fill lg:object-cover w-screen md:h-[500px]   xl:h-screen  "
          />
        </div>
        </Link> 


      </Carousel>
    </div>
  );
};

export default Header;
