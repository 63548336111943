import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addItem } from "../redux/cartSlice"; 
import { useNavigate } from "react-router-dom";


function NewProductCard({ product }) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

 
  const handleAddToCart = (product) => {
    dispatch(addItem(product));
    toast.success("Added to cart");
  };



  
  const goToNewProductDetails = () => {
 
    navigate(`/newproducts/${product.id}`) 
  };

  return (

    
    <div className="bg-[#F9F9F9] px-2 py-2 rounded-xl shadow-lg overflow-hidden h-[350px] w-[255px] ">

      <img
      onClick={ goToNewProductDetails  }
        src={ product.imageUrl}
        alt={product.title}
        className="w-[235px] h-[225px] object-cover rounded-lg cursor-pointer "
      />

      <div className="p-2">
        <h2 className="text-md font-semibold ">{product.title}</h2>
       
     




        <div className="py-1">

            <div className="flex items-center">

            {!product.oldPrice && !product.newPrice && (
  <span className="text-lg text-black font-semibold">
    ₹{product.price}
  </span>
)}

            
            {product.oldPrice && (
              <span className="text-black line-through mr-2 ">
              ₹  {product.oldPrice}
              </span>
            )}
            {product.newPrice && (
              <span className="text-green-500 py-1">
                ₹{product.newPrice}
              </span>
            )}







          </div>


          {product.stock ? (
    <span className="text-lg  font-semibold text-red-600">
    {product.stock}
      </span>
) : null}

  
        </div>
      </div>
    </div>
  );
}

export default NewProductCard;
