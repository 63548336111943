import React, { useContext } from "react";
import { FaUserTie,FaRupeeSign } from "react-icons/fa";
import myContext from "../context/myContext";
import DashboardTab from "./DashboardTab";
import {AiFillDollarCircle,AiOutlineUserAdd,AiOutlineShoppingCart} from 'react-icons/ai'


function Dashboard({products}) {
  const context = useContext(myContext);
  const { mode, product, ageProduct,onSaleProduct,  bestSaleProduct,      categoryProduct, user, order } = context;
  return (
    
    <section className="text-gray-600 body-font mt-10 mb-10  lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto  items-center">

<div className="flex flex-col items-center justify-center">
   <img className='w-72' src="https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg?size=626&ext=jpg&ga=GA1.2.159543150.1689348213&semt=sph" alt="" />
   <p className='text-xl lg:text-4xl px-3 pb-6 font-bold text-[#FC3]'> Welcome to Admin Dashboard</p>
</div>

      
      <div className="container mx-auto  mb-10 ">

        <div className="flex flex-wrap  text-center justify-center ">


          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">

            <div
              className=" border-2 hover:shadow-yellow-500  bg-white border-gray-300    p-4 rounded-xl"
           
            >
              <div
                className="text-black w-12 h-12 mb-3 inline-block"
                viewBox="0 0 24 24"
              >
                <AiOutlineShoppingCart size={50} />
              </div>
              <h2 className="title-font font-medium text-3xl text-black fonts1">
                {product.length + ageProduct.length + categoryProduct.length + onSaleProduct.length + bestSaleProduct.length}
              </h2>
              <p className=" text-black py-2 font-bold"> Total  Products</p>
            </div>
          </div>

          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">

            <div
              className=" border-2 hover:shadow-yellow-500  bg-white border-gray-300    p-4 rounded-xl"
           
            >
              <div
                className="text-black w-12 h-12 mb-3 inline-block"
                viewBox="0 0 24 24"
              >
                <FaRupeeSign size={50} />
              </div>
              <h2 className="title-font font-medium text-3xl text-black fonts1">
                {order.length}
              </h2>
              <p className=" text-black py-2 font-bold">Orders</p>
            </div>
          </div>


          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">

<div
  className=" border-2 hover:shadow-yellow-500  bg-white border-gray-300    p-4 rounded-xl"

>
  <div
    className="text-black w-12 h-12 mb-3 inline-block"
    viewBox="0 0 24 24"
  >
    <FaUserTie size={50} />
  </div>
  <h2 className="title-font font-medium text-3xl text-black fonts1">
    {order.length}
  </h2>
  <p className=" text-black py-2 font-bold">Customers</p>
</div>
</div>
    
        </div>
      </div>

<div>
<DashboardTab />
</div>
     
    </section>
  );
}

export default Dashboard;
