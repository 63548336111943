import { useLocation } from 'react-router-dom';
import ProductCard from '../products/ProductCard';


const SearchResults = () => {
  const location = useLocation();
  const searchResults = location.state?.searchResults || [];

  return (
    <div className="flex flex-wrap -mx-4">
      {searchResults.map((product) => (
        <div key={product.id} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
          <ProductCard product={product} />
        </div>
      ))}
    </div>
  );
};

export default SearchResults;
