import React, { useContext, useEffect } from "react";
import myContext from "../context/myContext";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Cart } from "../redux/cartSlice";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";

function AllProducts() {

  const context = useContext(myContext);
  const {
    
    product,
  
  } = context;



  return (
    <section className="text-gray-600 body-font">
      <div className="container px-4 sm:px-6 py-4 sm:py-8 2xl:py-12 2xl:px-40">
       



        <div className="flex flex-wrap -mx-2 md:-mx-4">
          {product
         
            .map((item, index) => {
              const { title, price, imageUrl, id, category } = item;
              return (
                <div className="bg-[#F9F9F9] p-2 rounded-lg shadow-lg overflow-hidden h-[450px] w-[250px]">
                <img
                  src={imageUrl}
                  alt={title}
                  className="w-[235px] h-[280px] object-cover rounded-lg"
                />
                <div className="p-2 ">
                  <h2 className="text-xl font-semibold">{title}</h2>
             
                  <div className="py-3  ">
                    <span className="text-lg  text-black font-semibold">
                    ₹{price}
                    </span>
          
                    <div className='flex justify-between items-center py-4 '>
                    <button  className="px-10 py-2 bg-[#8CA43A] text-black rounded-xl">
                      Add to Bag
                    </button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
                    </div>
                 
                  </div>
                </div>
              </div>
              );
            })}
        </div>





      </div>
    </section>
  );
}

export default AllProducts;
