import React, { useState } from "react";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";

import { useNavigate } from "react-router-dom";

const Contact = () => {

  const navigate = useNavigate();
  
  const [name, setName] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const messageInfo = {
      name,
      phoneno,
      email,
      comment,
    };

    // Display a success message to the user.
    toast.success("Your Message has been sent");
    navigate('/')

    try {
      const messageRef = collection(fireDB, "messages");
      addDoc(messageRef, messageInfo);
    } catch (error) {
      console.error("Error storing contact info:", error);
    }
  };

  return (
    <>
      <h3 className="text-center text-3xl lg:text-[64px] font-semibold lg:mt-28 mt-12">
        Contact Us
      </h3>

      <div className="flex flex-wrap justify-center items-start space-y-20 lg:px-20 p-8 lg:py-16">

        <div className="xl:w-1/2 w-full xl:p-6">
          <h1 className="text-lg font-bold py-2">Form</h1>
          <p className="py-2">Get in touch by filling out the form!</p>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-semibold py-2" htmlFor="name">
                Name <span className="required">*</span>
              </label>
              <input
                className="bg-[#D9D9D9] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-semibold py-2" htmlFor="phoneno">
                Phone No. <span className="required">*</span>
              </label>
              <input
                className="bg-[#D9D9D9] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phoneno"
                type="tel"
                required
                value={phoneno}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-semibold py-2" htmlFor="email">
                E-mail <span className="required">*</span>
              </label>
              <input
                className="bg-[#D9D9D9] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label className="block text-sm font-semibold py-2" htmlFor="comment">
                Comment <span className="required">*</span>
              </label>
              <textarea
                className="bg-[#D9D9D9] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="comment"
                rows="4"
                required
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-[#fc3] text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="xl:w-[359px] w-full bg-[#4368EF]  lg:p-4 p-2 rounded-xl">
          <p className="text-white py-4 font-bold text-xl">Contact info</p>
          <p className="text-white py-4">
            Give us a call and we will help you out!
          </p>

          <p className="text-white py-4 flex gap-2">
            Phone/Whatsapp:{" "}
            <span className="text-white">
              +91 9045259705 <br />
              +91 9720172099
            </span>
          </p>

          <p className="text-white py-4 flex gap-2">
            Email:{" "}
            <span className="text-white">itikainternational@gmail.com</span>
          </p>

          <p className="text-white py-4 flex gap-2">
            Address{" "}
            <span className="text-white">
              {" "}
              #343, Anandpuri,
              <br /> railway road, Meerut,
              <br /> U.P., India <br /> Year of establishment - 2015
            </span>
          </p>
        </div>


      </div>
    </>
  );
};

export default Contact;
