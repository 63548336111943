import React from "react";
import { useState } from "react";

import ProductCard from "../products/ProductCard";
import Accordion from "../Accordian";

import { Helmet } from "react-helmet";

import NewProductCard from "../products/NewProductCard";

const OnSale = ({ products }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAgeCategories, setSelectedAgeCategories] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);

  const uniqueCategories = Array.from(
    new Set(products.map((product) => product.category))
  );
  const uniqueAgeCategories = Array.from(
    new Set(products.map((product) => product.age))
  );

  const toggleCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const toggleAgeCategory = (age) => {
    if (selectedAgeCategories.includes(age)) {
      setSelectedAgeCategories(selectedAgeCategories.filter((c) => c !== age));
    } else {
      setSelectedAgeCategories([...selectedAgeCategories, age]);
    }
  };

  const applyFilters = () => {
    // Filter products based on selected categories
    console.log("Selected Categories:", selectedCategories);
    const newFilteredProducts =
      selectedCategories.length === 0
        ? products
        : products.filter((product) =>
            selectedCategories.includes(product.category)
          );

    setFilteredProducts(newFilteredProducts);
  };

  const applyAgeFilters = () => {
    // Filter products based on selected categories

    const newFilteredProducts =
      selectedAgeCategories.length === 0
        ? products
        : products.filter((product) =>
            selectedAgeCategories.includes(product.age)
          );

    setFilteredProducts(newFilteredProducts);
  };

  const applyPriceFilters = () => {
    // Filter products based on selected categories and price range
    const newFilteredProducts = products.filter((product) => {
      const categoryMatch =
        selectedCategories.length === 0 ||
        selectedCategories.includes(product.category);
      const priceMatch = product.price >= minPrice && product.price <= maxPrice;
      return categoryMatch && priceMatch;
    });

    setFilteredProducts(newFilteredProducts);
  };

  const accordionItems = [
    {
      title: <h3 className="text-[20px]">Category</h3>,
      content: (
        <div className="flex-col">
          {uniqueCategories.map((category) => (
            <div key={category}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category)}
                  onChange={() => toggleCategory(category)}
                  className="py-2"
                />
                <span className="ml-2 text-[18px]">{category}</span>
              </label>
            </div>
          ))}
          <button
            onClick={applyFilters}
            className="bg-yellow-300 text-black py-2 px-4 mt-2"
          >
            Apply Filters
          </button>
        </div>
      ),
    },

    {
      title: <h3 className="text-[20px]">Age Group</h3>,
      content: (
        <div className="flex-col">
          {uniqueAgeCategories.map((age) => (
            <div key={age}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedAgeCategories.includes(age)}
                  onChange={() => toggleAgeCategory(age)}
                  className="py-2"
                />
                <span className="ml-2 text-[18px]">{age}</span>
              </label>
            </div>
          ))}
          <button
            onClick={applyAgeFilters}
            className="bg-yellow-300 text-black py-2 px-4 mt-2"
          >
            Apply Filters
          </button>
        </div>
      ),
    },

    {
      title: <h3 className="text-[20px]">Price Range</h3>,
      content: (
        <div className="flex-col">
          <div className="my-2 flex">
            <input
              className="border-2 border-gray-300 w-24  p-1 rounded-lg mr-2"
              type="number"
              value={minPrice}
              onChange={(e) => setMinPrice(Number(e.target.value))}
            />

            <input
              className="border-2 border-gray-300  p-1 rounded-lg w-24"
              type="number"
              value={maxPrice}
              onChange={(e) => setMaxPrice(Number(e.target.value))}
            />
          </div>

          <button
            onClick={applyPriceFilters}
            className="bg-yellow-300 text-black py-2 px-4 mt-2"
          >
            Apply Filters
          </button>
        </div>
      ),
    },
  ];

  //sorting

  const [sortType, setSortType] = useState(null);

  const handleSort = (type) => {
    setSortType(type);

    const sortedProducts = [...filteredProducts];
    if (type === "highToLow") {
      sortedProducts.sort((a, b) => b.price - a.price);
    } else if (type === "lowToHigh") {
      sortedProducts.sort((a, b) => a.price - b.price);
    }

    setFilteredProducts(sortedProducts);
  };

  return (
    <div className="xl:px-32 2xl:px-40 px-8 ">
      <Helmet>
        <title>
        Check Great Deals and Discount of the Day with - AnshitToys
        </title>
        <meta
          name="description"
          content="Get Attractive offers and discounts on Learning and Educational Toys for Kids Online in India. Visit Now! www.anshitoys.com"
        />
      </Helmet>

      <div className="xl:my-32 my-16 xl:w-[800px] xl:text-center mx-auto ">
        <h3 className="xl:text-center text-justify xl:text-4xl text-3xl font-semibold xl:pb-8 pb-4">
          On Sale
        </h3>
        <p className="xl:text-center  text-justify lg:text-2xl text-sm text-gray-600  ">
        Grab fantastic deals on educational toys. Limited-time discounts that make learning fun, and save your money. Don't miss out, shop now!
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-between">
        {/* Filter */}
        <div className="flex items-center gap-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M14.6538 6.53124C15.0496 6.53124 15.3663 6.21457 15.3663 5.81874V2.13749C15.3663 1.74165 15.0496 1.42499 14.6538 1.42499C14.258 1.42499 13.9413 1.74165 13.9413 2.13749V5.81874C13.9413 6.20665 14.2659 6.53124 14.6538 6.53124Z"
              fill="#292D32"
            />
            <path
              d="M9.49998 12.4688C9.10414 12.4688 8.78748 12.7854 8.78748 13.1812V16.8625C8.78748 17.2583 9.10414 17.575 9.49998 17.575C9.89581 17.575 10.2125 17.2583 10.2125 16.8625V13.1812C10.2125 12.7933 9.89581 12.4688 9.49998 12.4688Z"
              fill="#292D32"
            />
            <path
              d="M4.34629 6.53124C4.74212 6.53124 5.05879 6.21457 5.05879 5.81874V2.13749C5.05879 1.74165 4.74212 1.42499 4.34629 1.42499C3.95046 1.42499 3.63379 1.74165 3.63379 2.13749V5.81874C3.63379 6.20665 3.95046 6.53124 4.34629 6.53124Z"
              fill="#292D32"
            />
            <path
              d="M5.81875 8.05127H2.87375C2.47792 8.05127 2.16125 8.36794 2.16125 8.76377C2.16125 9.1596 2.47792 9.47627 2.87375 9.47627H3.63375V16.8625C3.63375 17.2584 3.95042 17.575 4.34625 17.575C4.74209 17.575 5.05875 17.2584 5.05875 16.8625V9.47627H5.81875C6.21459 9.47627 6.53125 9.1596 6.53125 8.76377C6.53125 8.36794 6.20667 8.05127 5.81875 8.05127Z"
              fill="#292D32"
            />
            <path
              d="M16.1263 8.05127H13.1812C12.7854 8.05127 12.4688 8.36794 12.4688 8.76377C12.4688 9.1596 12.7854 9.47627 13.1812 9.47627H13.9413V16.8625C13.9413 17.2584 14.2579 17.575 14.6538 17.575C15.0496 17.575 15.3663 17.2584 15.3663 16.8625V9.47627H16.1263C16.5221 9.47627 16.8388 9.1596 16.8388 8.76377C16.8388 8.36794 16.5221 8.05127 16.1263 8.05127Z"
              fill="#292D32"
            />
            <path
              d="M10.9724 9.52374H10.2124V2.13749C10.2124 1.74165 9.89578 1.42499 9.49994 1.42499C9.10411 1.42499 8.78744 1.74165 8.78744 2.13749V9.52374H8.02744C7.63161 9.52374 7.31494 9.8404 7.31494 10.2362C7.31494 10.6321 7.63161 10.9487 8.02744 10.9487H10.9724C11.3683 10.9487 11.6849 10.6321 11.6849 10.2362C11.6849 9.8404 11.3683 9.52374 10.9724 9.52374Z"
              fill="#292D32"
            />
          </svg>
          <p className="text-md">Filters</p>
        </div>

        {/* Sort */}
        <div className=" flex justify-between  lg:relative lg:inline-block lg:text-left">
          <div className="flex gap-2 items-center justify-between py-2 lg:py-0">
            <p>Price:</p>

            <div>
              <button
                onClick={() => handleSort("highToLow")}
                className={` p-1 lg:px-4 lg:py-2 border-2 border-gray-200 rounded text-xs ${
                  sortType === "highToLow" ? " text-black" : ""
                }`}
              >
                High to Low
              </button>

              <button
                onClick={() => handleSort("lowToHigh")}
                className={`p-1 lg:px-4 lg:py-2 mx-2  border-2 border-gray-200 rounded text-xs ${
                  sortType === "lowToHigh" ? " text-black" : ""
                }`}
              >
                Low to High
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="border-b border-[#B1B1B1] my-2"></div>

      {/* Main body */}

      <div className="flex flex-col  xl:flex-row  justify-center  items-start  pb-48">
        {/* Filter section */}

        <div className="flex-shrink xl:w-20 w-full ">
          <div className=" py-4">
            <Accordion items={accordionItems} />
          </div>
        </div>

        <div className="flex-grow xl:w-[800px] w-full xl:py-4 py-16 2xl:ml-10">
          <div className="flex flex-wrap xl:flex-row   gap-12 xl:justify-start xl:gap-20 2xl:gap-28 3xl:gap-20 md:justify-start">
            {filteredProducts.length > 0
              ? filteredProducts.map((product) => (
                  <div
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/4 3xl:w-1/5 mb-4"
                    key={product.id}
                  >
                    <NewProductCard product={product} />
                  </div>
                ))
              : products.map((product) => (
                  <div
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/4 3xl:w-1/5 mb-4"
                    key={product.id}
                  >
                    <NewProductCard product={product} />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnSale;
