import React from "react";
import Slider from "react-slick";
import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from "../assets/review-1.png";
import image2 from "../assets/review-2.png";
import image3 from "../assets/review-3.png";
import image4 from "../assets/review-4.png";

import { useNavigate } from "react-router-dom";


const Testimonials = () => {

  
  const navigate = useNavigate();
  const [sliderRef, setSliderRef] = useState(null);

  const categoryProducts = [
 
    { id: 1,  image: image1 },
    {
      id: 2,

      image: image2,
    },
    {
      id: 3,
 
      image: image3,
    },
    {
      id: 4,

      image: image4,
    },
 
  ];
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Number of products shown at a time
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },

      {
        breakpoint: 1280, 
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <div className=" md:px-20 px-4 sm:px-8 2xl:my-28 lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto  ">
      <div className=" ">
        <h2 className="text-3xl font-semibold lg:py-8 lg:text-[64px] text-gray-800 text-center mb-4 2xl:mb-2">
    Testimonials
        </h2>

        <Slider ref={setSliderRef} {...sliderSettings}>
          {categoryProducts.map((product) => (
            <div
              key={product.id}
              className="2xl:px-4   lg:px-2 2xl:py-12    "
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-[500px] object-contain  lg:object-cover  h-[300px] rounded-md mx-auto xl:object-cover "
              />
    

   
            </div>
          ))}
        </Slider>

        <div className="flex items-center justify-between     relative bottom-48">
          <button className=" " onClick={() => sliderRef?.slickPrev()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="30"
              viewBox="0 0 53 53"
              fill="none"
            >
              <circle cx="26.5" cy="26.5" r="26.5" fill="#22A35A" />
              <path
                d="M8.93934 25.9393C8.35355 26.5251 8.35355 27.4749 8.93934 28.0607L18.4853 37.6066C19.0711 38.1924 20.0208 38.1924 20.6066 37.6066C21.1924 37.0208 21.1924 36.0711 20.6066 35.4853L12.1213 27L20.6066 18.5147C21.1924 17.9289 21.1924 16.9792 20.6066 16.3934C20.0208 15.8076 19.0711 15.8076 18.4853 16.3934L8.93934 25.9393ZM43 25.5L10 25.5L10 28.5L43 28.5L43 25.5Z"
                fill="black"
              />
            </svg>
          </button>

          <button className="" onClick={() => sliderRef?.slickNext()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="30"
              viewBox="0 0 53 53"
              fill="none"
            >
              <circle cx="26.5" cy="26.5" r="26.5" fill="#22A35A" />
              <path
                d="M44.0607 28.0607C44.6464 27.4749 44.6464 26.5251 44.0607 25.9393L34.5147 16.3934C33.9289 15.8076 32.9792 15.8076 32.3934 16.3934C31.8076 16.9792 31.8076 17.9289 32.3934 18.5147L40.8787 27L32.3934 35.4853C31.8076 36.0711 31.8076 37.0208 32.3934 37.6066C32.9792 38.1924 33.9289 38.1924 34.5147 37.6066L44.0607 28.0607ZM10 28.5L43 28.5V25.5L10 25.5V28.5Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>


    </div>
  );
};

export default Testimonials;
