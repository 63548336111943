import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addItem } from "../redux/cartSlice"; 
import { useNavigate } from "react-router-dom";


function NewProductCard({ product }) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

 
  const handleAddToCart = (product) => {
    dispatch(addItem(product));
    toast.success("Added to cart");
  };



  
  const goToNewProductDetails = () => {
 
    navigate(`/newproducts/${product.id}`) 
  };

  return (
    <div className="bg-[#F9F9F9] p-2 rounded-lg shadow-lg overflow-hidden h-auto lg:h-[500px] w-full lg:w-[250px] ">

      <img
      onClick={ goToNewProductDetails  }
        src={ product.imageUrl}
        alt={product.title}
        className="w-full lg:w-[235px] h-[280px] object-cover rounded-lg cursor-pointer"
      />

      <div className="p-2">
        <h2 className="text-md font-semibold ">{product.title}</h2>
        <h2 className="text-md ">{product.age +"  "+ "Year" }</h2>
     

          {product.stock ? (
    <span className="text-lg  font-semibold text-red-600">
    {product.stock}
      </span>
) : null}




        <div className="py-3">

         
        {!product.oldPrice && !product.newPrice && (
  <span className="text-lg text-black font-semibold">
    ₹{product.price}
  </span>
)}

        

<div className="flex">

  {!product.price && product.oldPrice && (
    <span className="text-black line-through mr-2 py-1">
      ₹ {product.oldPrice}
    </span>
  )}
  {!product.price && product.newPrice && (
    <span className="text-green-500 py-1">
      ₹{product.newPrice}
    </span>
  )}
  
</div>


          {product.stock ? (
 null
) : 
<div className="flex justify-center items-center lg:py-4">
  <button
    onClick={() => handleAddToCart(product)} 
    className="px-10 py-2 bg-[#FC3] text-black rounded-xl"
  >
    Add to Cart
  </button>

 
</div>}

          

       

        </div>


      </div>
    </div>
  );
}

export default NewProductCard;
