
import React from 'react';
import { Link } from 'react-router-dom';

import image1 from '../assets/category/1.png'
import image2 from '../assets/category/2.png'
import image3 from '../assets/category/3.png'
import image4 from '../assets/category/4.png'

const ShopByCategory = () => {







  return (




  <div className="  lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto lg:px-2  my-20 px-8">
        <h2 className="lg:text-[64px] text-3xl font-semibold   text-gray-800 text-center mb-12 lg:py-8 ">Shop By Category</h2>
  
    <div className=" flex flex-wrap justify-center gap-4  ">
    
    <Link    to={'/educational-toys-for-kids-online'} >
    <div >
    <img className='object-contain lg:object-cover w-[525px] mx-auto h-auto lg:h-[250px]  rounded-2xl ' src={image1} alt="educational" />

    </div>
    </Link>
  
    <Link    to={'/indoor-active-play-toys-for-kids-online'} >
    <div >
    <img className='object-contain lg:object-cover w-[525px] mx-auto h-auto lg:h-[250px]   rounded-2xl ' src={image2} alt="active-play" />
    </div>
    </Link>


    <Link    to={'/puzzle-games-for-kids-online'} >
    <div >
    <img className='object-contain lg:object-cover w-[525px] mx-auto h-auto lg:h-[250px]   rounded-2xl'  src={image3} alt="puzzles" />

    </div>
    </Link>

    <Link    to={'/arts-and-crafts-kits-for-kids-online'} >
    <div >
    <img className='object-contain lg:object-cover w-[525px] mx-auto h-auto lg:h-[250px]   rounded-2xl ' src={image4} alt="art-and-craft" />
    </div>
    </Link>



       
     
  
    </div>

    <div className="flex justify-center items-center my-8 ">
    <Link    to={'/shop-by-category'} >     <button className="bg-[#FC3] 2xl:px-8 px-28 py-4 2xl:py-2 rounded-lg text-[20px] font-semibold">
          VIEW ALL
        </button>      </Link>
      </div>



  </div>


  );
};

export default ShopByCategory ;





