import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { removeItem } from '../redux/cartSlice';
import { toast } from 'react-toastify';
import { addDoc, collection } from 'firebase/firestore';
import { fireDB } from '../firebase/FirebaseConfig';
import {  useNavigate } from 'react-router-dom';
import Modal from '../dashboard/Modal';
import { clearCart } from '../redux/cartSlice';
import { Link } from 'react-router-dom';

const Cart = ({products}) => {

  const [paymentSuccess, setPaymentSuccess] = useState(false);



  const user = JSON.parse(localStorage.getItem("user"));


  const cartItems = useSelector((state) => state.cart.items);


  let [isOpen, setIsOpen] = useState(false)

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  // Total amount of items
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    let temp = 0;
    cartItems.forEach((cartItem) => {
      temp += parseInt(cartItem.price || cartItem.newPrice);
    });
    setTotalAmount(temp);
  }, [cartItems]);

  const shipping = parseInt(100);
  const grandTotal =  totalAmount;
 



  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteItem = (index) => {
  
    dispatch(removeItem(index));
    toast.success('Item removed from the cart');
  };



  const handleAddMoreItems = () => {
    navigate('/shop-by-category');
  }

 


//payment integration


const buyNow = async () => {
  // Validation
  if (name === '' || address=== '' || pincode === '' || phoneNumber === '') {
    return toast.error('All fields are required', {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  const addressInfo = {
    name,
    address,
    pincode,
    phoneNumber,
    date: new Date().toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }),
  };

  var options = {
    key:process.env.REACT_APP_RAZORPAY_KEY_ID,
    key_secret:process.env.REACT_APP_RAZORPAY_KEY_SECRET,
    amount: parseInt(grandTotal * 100),
    currency: 'INR',
    order_receipt: 'order_rcptid_' + name,
    name: 'Anshit Toys',
    description: 'Educational Toys Selling Comapny',
    handler: function (response) {
      // console.log(response)
      toast.success('Payment Successful');
      setPaymentSuccess(true); 
     
      const paymentId = response.razorpay_payment_id;


          // Dispatch an action to clear the cart items
          cartItems.forEach((item) => {
            dispatch(clearCart(item));
          });

      // Store in Firebase
      const orderInfo = {
        cartItems,
        addressInfo,
        date: new Date().toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        }),
        // email: user.user.email,
        // userid: user.user.uid,
        paymentId,
      };

      try {
        const orderRef = collection(fireDB, 'order');
        addDoc(orderRef, orderInfo);
      } catch (error) {
        console.log(error);
      }
    },
    theme: {
      color: '#3399cc',
    },
  };
  var pay = new window.Razorpay(options);
  pay.open();
 

};



// if paymentSuccess is true
if (paymentSuccess) {
  return (
    <div className="flex flex-col items-center justify-center h-[600px]">
      <h1 className="text-4xl md:text-6xl font-bold text-center mb-8">Thank you for shopping with us!</h1>
      <Link to="/" className="bg-[#FC3] text-white px-6 py-3 rounded-md shadow-md  transition duration-300 ease-in-out">Go to Home</Link>
    </div>
  );
}




  return (
    <>
      {cartItems.length > 0 ? (
        <div>
          <h3 className="text-2xl text-center xl:text-5xl my-8 xl:my-16 px-8 xl:ml-48 2xl:text-left 2xl:px-0">Cart</h3>
          <div className="px-8 flex flex-wrap md:justify-between xl:space-x-50 md:px-20 xl:px-36 2xl:px-44">
            <div>

            
           
              {cartItems.map((item, index) => (

                
                <div key={index}  className="flex justify-between items-center my-12">
                  <div className="flex gap-4">
                    <div>
                <img    className="cursor-pointer w-48 h-48 object-cover" src={item.image ? item.image : item.imageUrl} alt="" /> 
                    </div>
                    <div>
                    <p className="text-md xl:text-lg">{item.name ? item.name : item.title}</p>


                   
                      <p className="font-bold text-md py-2">₹{item.price || item.newPrice}</p>
                      <button
                        className="text-gray-500 py-2 mt-20"
                        onClick={() => handleDeleteItem(index)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}





            </div>
            <div className="mt-6 w-full md:w-1/3 mb-8">
              <div className="mb-2 flex justify-between">
                <p className="text-gray-700">Subtotal - {cartItems.length} <span>Items</span></p>
                <p className="text-gray-700">₹{totalAmount}</p>
              </div>
              <hr className="my-4 border-gray-500" />
              <div className="flex justify-center items-center my-4">
                <button onClick={handleAddMoreItems} className="bg-[#FC3] 2xl:px-8 px-20 py-4 2xl:py-2 rounded-lg text-[20px] font-semibold w-full">
                  ADD MORE ITEMS
                </button>
              </div>
            

              <Modal
                name={name}
                city={address}
                pincode={pincode}
                phoneNumber={phoneNumber}
                setName={setName}
                setAddress={setAddress}
                setPincode={setPincode}
                setPhoneNumber={setPhoneNumber}
                buyNow={buyNow}
              />

            </div>

            
          </div>
        </div>
      ) : (
        <div className="bg-gray-100 min-h-screen pt-48 md:pt-32 text-center">
          <h2 className="md:text-2xl text-lg text-blue-500 font-bold">No items in the cart!</h2>
          <img className="mx-auto my-4" src="https://cdn-icons-png.flaticon.com/128/11010/11010851.png" alt="" />
        </div>
      )}
    </>
  );
};

export default Cart;