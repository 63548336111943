import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaSearch, FaUserCircle, FaShoppingCart } from "react-icons/fa";
import { AiOutlineLogin } from "react-icons/ai";
import {BiLogOutCircle} from 'react-icons/bi'
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProductCard from '../components/products/ProductCard'
import {RiAdminFill} from 'react-icons/ri'
import {GrUserAdmin} from 'react-icons/gr'
import NewProductCard from "./products/NewProductCard";

const Navbar = ({  product, onSaleProduct, bestSaleProduct, ageProduct, categoryProduct  }) => {

 
  const user = JSON.parse(localStorage.getItem("user"));

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [open, setOpen] = useState(false);
  const [searchWindowOpen, setSearchWindowOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  


  const handleSearch = (e) => {
    e.preventDefault();
  
 
    const allProducts = [
      ...(product || []),
      ...(onSaleProduct || []),
      ...(bestSaleProduct || []),
      ...(ageProduct || []),
      ...(categoryProduct || []),
    ];
  

  
 
    const filteredProducts = allProducts.filter((product) => {

      if ( product.title) {
        const productNameLower = product.title.toLowerCase();
        const searchQueryLower = searchQuery.toLowerCase();
  
      
  
        const includes = productNameLower.includes(searchQueryLower);
    
        return includes;
      
  
      }

     
      // If product or product.name is undefined, exclude it from the filtered results
      return false;
    });
  
    console.log('Filtered Products:', filteredProducts);
  
    setSearchResults(filteredProducts);
  
    // Open the search window
    setSearchWindowOpen(true);
  
    // Close the mobile menu (optional)
    setOpen(false);
  };
  
  
  
  

  
  const closeSearchWindow = () => {
    setSearchWindowOpen(false);
  };
  
  const logout = () => {
    localStorage.clear("user");
   navigate("/login");
  };

  

  const cardItems = useSelector((store) => store.cart.items);

const navigate = useNavigate();

  const handleCart = () => {
    navigate('/cart')
  }


 


  return (


    
    <nav className=" bg-[#FC3] py-4 lg:pt-4 lg:pb-4  px-2     ">

      <div className=" flex justify-between   lg:max-w-[900px]  xl:max-w-[1100px] lg:container 2xl:max-w-[1300px] lg:mx-auto  items-center xl:px-4     ">


        <Link to={"/"}>
      
          <img
            className="w-36 lg:w-48 "
            src={logo}
            alt="logo"
          />
        </Link>

       
    


<div className="flex flex-row  justify-center md:flex-row gap-2 lg:gap-4 items-center text-center">
  
   {/* <input
    className="p-2 absolute    md:relative  md:left-[55px]   left-[105px]   lg:left-[65px] border-1 shadow-lg z-50  rounded-3xl bg-white max-w-[165px] h-[50px] md:w-[260px] h-35px md:h-[50px]"
    type="text"
    value={searchQuery}
    onChange={handleSearchChange}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        handleSearchEnter();
      }
    }}
  />  */}

<input
    className="p-2     border-1 shadow-lg z-50 max-w-[150px]  h-[40px] rounded-3xl bg-white md:relative md:left-[55px] lg:left-[65px] md:max-w-[400px] md:h-[50px] "
    type="text"
    value={searchQuery}
    onChange={handleSearchChange}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        // handleSearchEnter();
      }
    }}
  /> 



  <div className="bg-black rounded-full p-2 z-50">

    <svg
      onClick={handleSearch}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M13.4167 24.5C19.5378 24.5 24.5 19.5378 24.5 13.4167C24.5 7.29552 19.5378 2.33334 13.4167 2.33334C7.29552 2.33334 2.33334 7.29552 2.33334 13.4167C2.33334 19.5378 7.29552 24.5 13.4167 24.5Z"
        fill="white"
      />
      <path
        d="M24.85 25.6667C24.64 25.6667 24.43 25.585 24.2783 25.4333L22.1083 23.2633C21.7933 22.9483 21.7933 22.435 22.1083 22.1083C22.4233 21.7933 22.9367 21.7933 23.2633 22.1083L25.4333 24.2783C25.7483 24.5933 25.7483 25.1067 25.4333 25.4333C25.27 25.585 25.06 25.6667 24.85 25.6667Z"
        fill="white"
      />
    </svg>
  </div>

  <div className="bg-black rounded-full p-2 cursor-pointer">
    <FaShoppingCart onClick={handleCart} size={28} color="white" />
  </div>

  {cardItems.length > 0 ? (
    <span className='bg-black text-white lg:p-4 p-3 rounded-full absolute right-[50px] top-[10px]  lg:relative lg:right-6 lg:bottom-20     w-2 h-2 flex items-center justify-center lg:text-lg text-sm'>
      {cardItems.length}
    </span>
  ) : null}


<div className="hidden lg:flex lg:items-end lg:gap-4 ">


  {user?.user?.email === "admin@anshitoys.com" ? (
    <Link to={"/dashboard"}>
      <div>
   
      <p   className="text-[20px] font-bold text-black ">Admin</p>
      </div>
    
  
    </Link>
  ) : null}

  {/* {!user ? (
    <Link to={"/login"} className=" ">
     <p   className="text-[20px] font-bold  text-black ">Login</p>


       

    </Link>
  ) : null} */}



{/* {user ? (
  <Link to="/" onClick={logout} className=" cursor-pointer ">
  <p   className="text-[20px] font-bold text-black ">Logout</p>
  </Link>
) : null}  */}

</div>


<button
          type="button"
          className=" rounded-md   bg-black p-2 text-white lg:hidden"
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">Open menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>



</div>




         

      </div>

      {/* navlinks */}

      <div className=" hidden    lg:flex   lg:justify-center  md:hidden 2xl:pt-4 lg:py-2  ">
       
       
        <ul className=" text-[20px] lg:text-[24px]  flex justify-between items-center lg:container lg:mx-auto  lg:max-w-[900px]  xl:max-w-[1100px] 2xl:max-w-[1300px]   text-black font-bold">

          <li className="hover:text-white transition duration-300 ease-in-out ">
            <Link to={"/"}>Home</Link>
          </li>

        {/* Existing navigation items */}
<ul className="flex space-x-4">
 

  {/* Add a dropdown wrapper */}
  <li className="relative group">
<Link to={'/shop-by-category'}>    <span className="hover:text-white cursor-pointer transition duration-300 ease-in-out">
      Categories
    </span> </Link>
    {/* Dropdown menu */}
    <ul className="hidden absolute top-full left-0 z-10 bg-white py-4 px-8 space-y-2 group-hover:block">
      <li>
        <Link to={"/educational-toys-for-kids-online"} className="block px-4 py-2 hover:bg-yellow-100"> Educational</Link>
      </li>
      <li>
        <Link to={"/indoor-active-play-toys-for-kids-online"} className="block px-4 py-2 hover:bg-yellow-100">Active Play</Link>
      </li>

      <li>
        <Link to={"/puzzle-games-for-kids-online"} className="block px-4 py-2 hover:bg-yellow-100">Puzzles</Link>
      </li>

      <li>
        <Link to={"/arts-and-crafts-kits-for-kids-online"} className="block px-4 py-2 hover:bg-yellow-100">Art & Craft</Link>
      </li>
    
    </ul>
  </li>

</ul>



<ul className="flex space-x-4">

  {/* Add a dropdown wrapper */}
  <li className="relative group">
  <Link to={'/shop-by-age'}> <span className="hover:text-white cursor-pointer transition duration-300 ease-in-out">
     Age
    </span> </Link> 
   
    <ul className="hidden absolute top-full left-0 z-10 bg-white py-4 px-8 space-y-2 group-hover:block">
      <li>
        <Link to={"/learning-toys-for-1-year-olds"} className="block px-4 py-2 hover:bg-yellow-100 " style={{ whiteSpace: "nowrap" }}>
          0-12 Months
        </Link>
      </li>
      <li>
        <Link to={"/learning-toys-for-1-to-2-year-olds"} className="block px-4 py-2 hover:bg-yellow-100 " style={{ whiteSpace: "nowrap" }}>
          1-2 Years
        </Link>
      </li>
      <li>
        <Link to={"/learning-toys-for-3-to-5-year-olds"} className="block px-4 py-2 hover:bg-yellow-100" style={{ whiteSpace: "nowrap" }}>
          3-5 Years
        </Link>
      </li>
      <li>
        <Link to={"/learning-toys-for-6-to-8-year-olds"} className="block px-4 py-2 hover:bg-yellow-100" style={{ whiteSpace: "nowrap" }}>
         6-8 Years
        </Link>
      </li>

      <li>
        <Link to={"/learning-toys-for-9-to-11-year-olds"} className="block px-4 py-2 hover:bg-yellow-100" style={{ whiteSpace: "nowrap" }}>
         9-11 Years
        </Link>
      </li>

      <li>
        <Link to={"/learning-toys-for-12-year-olds"} className="block px-4 py-2 hover:bg-yellow-100" style={{ whiteSpace: "nowrap" }}>
         12+ Years
        </Link>
      </li>
    </ul>
  </li>
</ul>



          <li className="hover:text-white transition duration-300 ease-in-out">
            <Link to={"/best-selling-toys-and-games-for-kids"}>Best Selling</Link>
          </li>

          <li className="hover:text-white transition duration-300 ease-in-out">
            <Link to={"/deals-and-discount-of-the-day"}>On Sale</Link>
          </li>

          <li className="hover:text-white transition duration-300 ease-in-out">
            <Link to={"/new-arrivals-best-toys-for-kids"}>New Arrivals</Link>
          </li>
        </ul>


        
      </div>

      {/* for mobile  */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden " onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-[#FC3] pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-12">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-black"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <RxCross2 size={30} className="relative bottom-2" />
                  </button>
                </div>

                <div   className="space-y-6 border-t border-black p-4 ">
                  <Link to={"/"} className="text-sm font-medium text-gray-900 " onClick={() => setOpen(false)}>
                    Home
                  </Link>

                  <div className="flow-root">
                    <Link 
                    onClick={() => setOpen(false)}
                      to={"/shop-by-category"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                      Category
                    </Link>
                  </div>

                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/shop-by-age"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                      Age
                    </Link>
                  </div>

                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/best-selling-toys-and-games-for-kids"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                      Best Selling
                    </Link>
                  </div>

                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/deals-and-discount-of-the-day"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                      On Sale
                    </Link>
                  </div>

                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/new-arrivals-best-toys-for-kids"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                      New Arrivals
                    </Link>
                  </div>


                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/about"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                    About us
                    </Link>
                  </div>




                  <div className="flow-root">
                    <Link
                    onClick={() => setOpen(false)}
                      to={"/contact"}
                      className="text-sm font-medium text-gray-900 "
                    >
                      {" "}
                     Contact
                    </Link>
                  </div>



                  <div className="flow-root">

                  {user?.user?.email === "admin@anshitoys.com" ? (
    <Link to={"/dashboard"}     onClick={() => setOpen(false)}>
      <div>
   
      <p   className="text-sm font-medium text-gray-900 ">Admin</p>
      </div>
    
  
    </Link>
  ) : null}


                    </div>




                    <div className="flow-root">
      {user ? (
        <Link to="/" onClick={() => { logout(); setOpen(false); }} className="cursor-pointer">
          <p className="text-sm font-medium text-gray-900">Logout</p>
        </Link>
      ) : null}
    </div>

          


                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>



   
 
 {/* search results */}
 <Transition.Root show={searchWindowOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto" onClose={closeSearchWindow}>
          <div className="flex items-end justify-center min-h-screen pt-8 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div  onClick={closeSearchWindow} className=" bg-white inline-block align-bottom sm:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Search Results
                      </h3>
                      <div className="mt-2">
                        {/* Display your search results here */}
                        <div className="flex flex-wrap -mx-4">
                          {searchResults.map((product) => (
                            <div
                              key={product.id}
                              className="w-full  p-4"
                            >
                              <NewProductCard product={product} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={closeSearchWindow}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>


    </nav>
  );
};

export default Navbar;