import React, { useContext, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import myContext from "../context/myContext";

import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaUser, FaCartPlus } from "react-icons/fa";
import {AiOutlineMessage} from 'react-icons/ai'
import {
  AiFillShopping,
  AiFillPlusCircle,
  AiFillDelete,
  AiFillMessage,
  AiOutlineRollback,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";
import {CiMoneyCheck1} from 'react-icons/ci'

function DashboardTab() {
  const context = useContext(myContext);
  const {
    product,
    onSaleProduct,
    bestSaleProduct,
    ageProduct,
    categoryProduct,
    
    edithandle,
    editOnSale,
    editBestSale,
 

    editAge,
    editCategory,
   
    

    deleteProduct,
    deleteOnSaleProduct,
    deleteBestSaleProduct,
    deleteAgeProduct,
    deleteCategoryProduct,
    deleteMessage,
    deleteOrder,


    order,
    user,
    message,
  } = context;
  const navigate = useNavigate();

  // console.log(product)
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const logout = () => {
    localStorage.clear("user");
    navigate("/login");
  };

  const add = () => {
    navigate("/addproduct");
  };

  const addOnSale = () => {
    navigate("/addonsaleproduct");
  };

  const addBestSale = () => {
    navigate("/addbestsaleproduct");
  };

  const addAge = () => {
    navigate("/addageproduct");
  };

  const addCategory = () => {
    navigate("/addcategoryproduct");
  };

  return (
    <>
      <div className="container mx-auto px-4 ">
        <div className="tab container  mx-auto ">
          <Tabs defaultIndex={0} className=" ">

            <TabList className="flex flex-wrap justify-center gap-16 py-2 md:py-0">
              <Tab>
                <button
                  type="button"
                  className="w-48 h-36 font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                >
                  <div className="flex flex-col gap-2 items-center">
                    <MdOutlineProductionQuantityLimits size={36} />
                    New Arrivals
                  </div>
                </button>
              </Tab>

              {/* Repeat the above Tab structure for other tabs with necessary modifications */}

              <Tab>
                <button
                  type="button"
                  className="w-48 h-36  font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                >
                  <div className="flex flex-col gap-2 items-center">
                    <MdOutlineProductionQuantityLimits size={36} />
                    OnSale
                  </div>
                </button>
              </Tab>

              <Tab>
                <button
                  type="button"
                  className="w-48 h-36  font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                >
                  <div className="flex flex-col gap-2 items-center">
                    <MdOutlineProductionQuantityLimits size={36} />
                    Best Selling
                  </div>
                </button>
              </Tab>

              <Tab>
                <button
                  type="button"
                  className="w-48 h-36  font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                >
                  <div className="flex flex-col gap-2 items-center">
                    <MdOutlineProductionQuantityLimits size={36} />
                    Shop By Age
                  </div>
                </button>
              </Tab>

              <Tab>
                <button
                  type="button"
                  className="w-48 h-36  font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                >
                  <div className="flex flex-col gap-2 items-center">
                    <MdOutlineProductionQuantityLimits size={36} />
                    Shop By Category
                  </div>
                </button>
              </Tab>

              <Tab>
                <button
                  type="button"
                  className="w-48 h-36  font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                >
                  <div className="flex flex-col gap-2 items-center">
                    <CiMoneyCheck1 size={36} />
                    Orders
                  </div>
                </button>
              </Tab>



              {/* Messages */}
              <Tab>
                <button
                  type="button"
                  className="w-48 h-36  font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                >
                  <div className="flex flex-col gap-2 items-center">
                    <AiOutlineMessage size={36} />
                    Messages
                  </div>
                </button>
              </Tab>

              {/* Logout */}

              
              {!user ? (
                <Tab>
                  <button
                    onClick={logout}
                    type="button"
                    className="w-48 h-36  font-medium border-b-2 border-gray-300 text-black rounded-lg text-xl px-5 py-1.5 text-center transition duration-300 transform hover:scale-105 hover:bg-gray-200 hover:shadow-md"
                  >
                    <div className="flex flex-col gap-2 items-center">
                      <BiLogOutCircle size={28} />
                      Logout
                    </div>
                  </button>
                </Tab>
              ) : null}






            </TabList>



            {/* new arrivals  product  */}
            <TabPanel>
              <div className="  px-4 md:px-0 mb-16 my-16">
                <div className=" flex justify-center my-10 ">
                  <button
                    onClick={add}
                    type="button"
                    className="font-medium border-b-2  border-gray-300 text-black rounded-lg text-xl  px-5 py-1.5 text-center bg-gray-100 "
                  >
                    <div className="flex gap-2 items-center ">
                      Add Products in New Arrivals Section
                    </div>
                  </button>
                </div>

                <div className="relative overflow-x-auto  my-4">
                  <table className="w-full text-sm text-left text-black ">
                    <thead className="text-xs border border-gray-600 text-black uppercase">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          S.No
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Image
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Product Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Category
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Update/Delete Product
                        </th>
                      </tr>
                    </thead>
                    {product.map((item, index) => {
                      const {
                        title,
                        price,
                        newPrice,
                        imageUrl,
                        category,

                        date,
                      } = item;
                      return (
                        <tbody className="">
                          <tr className=" border-b-2  ">
                            <td className="px-6 py-4 text-black ">
                              {index + 1}.
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-black whitespace-nowrap"
                            >
                              <img className="w-64" src={imageUrl} alt="img" />
                            </th>
                            <td className="px-6 py-4 text-black ">{title}</td>
                            <td className="px-6 py-4 text-black ">₹{price} {newPrice}</td>
                            <td className="px-6 py-4 text-black ">
                              {category}
                            </td>
                            <td className="px-6 py-4 text-black ">{date}</td>
                            <td className="px-6 py-4">
                              <div className=" flex gap-2">
                                <div className=" flex gap-2 cursor-pointer text-black ">
                                  <div onClick={() => deleteProduct(item)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                      />
                                    </svg>
                                  </div>

                                  <Link to={"/updateproduct"}>
                                    <div onClick={() => edithandle(item)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </TabPanel>

            {/* Onsale Products */}

            <TabPanel>
              <div className="  px-4 md:px-0 mb-16">
                <div className=" flex justify-center my-10 ">
                  <button
                    onClick={addOnSale}
                    type="button"
                    className="font-medium border-b-2  border-gray-300 text-black rounded-lg text-xl  px-5 py-1.5 text-center bg-gray-100 "
                  >
                    <div className="flex gap-2 items-center ">
                      Add Products in OnSale Section
                    </div>
                  </button>
                </div>

                <div className="relative overflow-x-auto  my-4">
                  <table className="w-full text-sm text-left text-black ">
                    <thead className="text-xs border border-gray-600 text-black uppercase">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          S.No
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Image
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Product Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Category
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Update/Delete Product
                        </th>
                      </tr>
                    </thead>
                    {onSaleProduct.map((item, index) => {
                      const {
                        title,
                        price,
                        newPrice,
                        imageUrl,
                        category,

                        date,
                      } = item;
                      return (
                        <tbody className="">
                          <tr className=" border-b-2  ">
                            <td className="px-6 py-4 text-black ">
                              {index + 1}.
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-black whitespace-nowrap"
                            >
                              <img className="w-64" src={imageUrl} alt="img" />
                            </th>
                            <td className="px-6 py-4 text-black ">{title}</td>
                            <td className="px-6 py-4 text-black ">₹{price} {newPrice}</td>
                            <td className="px-6 py-4 text-black ">
                              {category}
                            </td>
                            <td className="px-6 py-4 text-black ">{date}</td>
                            <td className="px-6 py-4">
                              <div className=" flex gap-2">
                                <div className=" flex gap-2 cursor-pointer text-black ">
                                  <div onClick={() => deleteOnSaleProduct(item)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                      />
                                    </svg>
                                  </div>

                                  <Link to={"/updateonsaleproduct"}>
                                    <div onClick={() => editOnSale(item)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </TabPanel>

            {/* Best Selling Products */}

            <TabPanel>
              <div className="  px-4 md:px-0 mb-16">
                <div className=" flex justify-center my-10 ">
                  <button
                    onClick={addBestSale}
                    type="button"
                    className="font-medium border-b-2  border-gray-300 text-black rounded-lg text-xl  px-5 py-1.5 text-center bg-gray-100 "
                  >
                    <div className="flex gap-2 items-center ">
                      Add Products in Best Selling Section
                    </div>
                  </button>
                </div>

                <div className="relative overflow-x-auto  my-4">
                  <table className="w-full text-sm text-left text-black ">
                    <thead className="text-xs border border-gray-600 text-black uppercase">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          S.No
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Image
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Product Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Category
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Update/Delete Product
                        </th>
                      </tr>
                    </thead>
                    {bestSaleProduct.map((item, index) => {
                      const {
                        title,
                        price,
                        imageUrl,
                        category,
                        newPrice,

                        date,
                      } = item;
                      return (
                        <tbody className="">
                          <tr className=" border-b-2  ">
                            <td className="px-6 py-4 text-black ">
                              {index + 1}.
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-black whitespace-nowrap"
                            >
                              <img className="w-64" src={imageUrl} alt="img" />
                            </th>
                            <td className="px-6 py-4 text-black ">{title}</td>
                            <td className="px-6 py-4 text-black ">₹{price} {newPrice}</td>
                            <td className="px-6 py-4 text-black ">
                              {category}
                            </td>
                            <td className="px-6 py-4 text-black ">{date}</td>
                            <td className="px-6 py-4">
                              <div className=" flex gap-2">
                                <div className=" flex gap-2 cursor-pointer text-black ">
                                  <div onClick={() => deleteBestSaleProduct(item)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                      />
                                    </svg>
                                  </div>

                                  <Link to={"/updatebestsaleproduct"}>
                                    <div onClick={() => editBestSale(item)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </TabPanel>

            {/* Age Products */}
            <TabPanel>
              <div className="  px-4 md:px-0 mb-16">
                <div className=" flex justify-center my-10 ">
                  <button
                    onClick={addAge}
                    type="button"
                    className="font-medium border-b-2  border-gray-300 text-black rounded-lg text-xl  px-5 py-1.5 text-center bg-gray-100 "
                  >
                    <div className="flex gap-2 items-center ">
                      Add Products in Shop By Age Section
                    </div>
                  </button>
                </div>

                <div className="relative overflow-x-auto  my-4">
                  <table className="w-full text-sm text-left text-black ">
                    <thead className="text-xs border border-gray-600 text-black uppercase">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          S.No
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Image
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Product Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Category
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Update/Delete Product
                        </th>
                      </tr>
                    </thead>

                    {ageProduct.map((item, index) => {
                      const {
                        title,
                        price,
                        imageUrl,
                        category,
                        newPrice,

                        date,
                      } = item;
                      return (
                        <tbody className="">
                          <tr className=" border-b-2  ">
                            <td className="px-6 py-4 text-black ">
                              {index + 1}.
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-black whitespace-nowrap"
                            >
                              <img className="w-64" src={imageUrl} alt="img" />
                            </th>
                            <td className="px-6 py-4 text-black ">{title}</td>
                            <td className="px-6 py-4 text-black ">₹{price} {newPrice}</td>
                            <td className="px-6 py-4 text-black ">
                              {category}
                            </td>
                            <td className="px-6 py-4 text-black ">{date}</td>
                            <td className="px-6 py-4">
                              <div className=" flex gap-2">
                                <div className=" flex gap-2 cursor-pointer text-black ">
                                  <div onClick={() => deleteAgeProduct(item)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                      />
                                    </svg>
                                  </div>

                                  <Link to={"/updateageproduct"}>
                                    <div onClick={() => editAge(item)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </TabPanel>

            {/* shop by category products */}

            <TabPanel>
              <div className="  px-4 md:px-0 mb-16">
                <div className=" flex justify-center my-10 ">
                  <button
                    onClick={addCategory}
                    type="button"
                    className="font-medium border-b-2  border-gray-300 text-black rounded-lg text-xl  px-5 py-1.5 text-center bg-gray-100 "
                  >
                    <div className="flex gap-2 items-center ">
                      Add Products in Shop By Category Section
                    </div>
                  </button>
                </div>

                <div className="relative overflow-x-auto  my-4">
                  <table className="w-full text-sm text-left text-black ">
                    <thead className="text-xs border border-gray-600 text-black uppercase">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          S.No
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Image
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Product Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Category
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Update/Delete Product
                        </th>
                      </tr>
                    </thead>

                    {categoryProduct.map((item, index) => {
                      const {
                        title,
                        price,
                        imageUrl,
                        category,
                        newPrice,

                        date,
                      } = item;
                      return (
                        <tbody className="">
                          <tr className=" border-b-2  ">
                            <td className="px-6 py-4 text-black ">
                              {index + 1}.
                            </td>
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-black whitespace-nowrap"
                            >
                              <img className="w-64" src={imageUrl} alt="img" />
                            </th>
                            <td className="px-6 py-4 text-black ">{title}</td>
                            <td className="px-6 py-4 text-black ">₹{price} {newPrice}</td>
                            <td className="px-6 py-4 text-black ">
                              {category}
                            </td>
                            <td className="px-6 py-4 text-black ">{date}</td>
                            <td className="px-6 py-4">
                              <div className=" flex gap-2">
                                <div className=" flex gap-2 cursor-pointer text-black ">
                                  <div onClick={() => deleteCategoryProduct(item)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                      />
                                    </svg>
                                  </div>

                                  <Link to={"/updatecategoryproduct"}>
                                    <div onClick={() => editCategory(item)}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </TabPanel>

           

            {/* order info with payment integration */}

            {/* <TabPanel>
              <div className="relative overflow-x-auto my-20">
                <h2 className="text-4xl text-center font-bold my-8">
                  Order Details
                </h2>

                {order.map((allorder, index) => {
                  return (
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-black uppercase bg-gray-200 ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Payment Id
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Image
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Title
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Price
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Category
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                           Address
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Pincode
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Phone Number
                          </th>

                          <th scope="col" className="px-6 py-3">
                            Date
                          </th>
                        </tr>
                      </thead>
                      {allorder.cartItems.map((item, index) => {
                        // console.log(allorder)
                        const { title, description, category, image, imageUrl,price,newPrice } =
                          item;
                        return (
                          <tbody>
                            <tr className="bg-gray-50 border-b  dark:border-gray-700">
                              <td className="px-6 py-4 text-black ">
                                {allorder.paymentId}
                              </td>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-black whitespace-nowrap"
                              >
                                <img className="w-16" src={imageUrl || image} alt="img" />
                              </th>
                              <td className="px-6 py-4 text-black ">
                                {title}
                              </td>
                              <td className="px-6 py-4 text-black ">
                                ₹{newPrice || price}
                              </td>
                              <td className="px-6 py-4 text-black ">
                                {category}
                              </td>

                              <td className="px-6 py-4 text-black ">
                                {allorder.addressInfo.name}
                              </td>
                              <td className="px-6 py-4 text-black ">
                                {allorder.addressInfo.address}
                              </td>
                              <td className="px-6 py-4 text-black ">
                                {allorder.addressInfo.pincode}
                              </td>
                              <td className="px-6 py-4 text-black ">
                                {allorder.addressInfo.phoneNumber}
                              </td>

                              <td className="px-6 py-4 text-black ">
                                {allorder.date}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  );
                })}
              </div>
            </TabPanel> */}

<TabPanel>
  <div className="relative overflow-x-auto my-20">
    <h2 className="text-4xl text-center font-bold my-8">
      Order Details
    </h2>

    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-black uppercase bg-gray-200 ">
        <tr>
          <th scope="col" className="px-6 py-3">
            Payment Id
          </th>
          <th scope="col" className="px-6 py-3">
            Image
          </th>
          <th scope="col" className="px-6 py-3">
            Title
          </th>
          <th scope="col" className="px-6 py-3">
            Price
          </th>
          <th scope="col" className="px-6 py-3">
            Category
          </th>
          <th scope="col" className="px-6 py-3">
            Name
          </th>
          <th scope="col" className="px-6 py-3">
            Address
          </th>
          <th scope="col" className="px-6 py-3">
            Pincode
          </th>
          <th scope="col" className="px-6 py-3">
            Phone Number
          </th>
          <th scope="col" className="px-6 py-3">
            Date
          </th>
        </tr>
      </thead>
      <tbody>
        {order.map((allorder, index) => {
          return allorder.cartItems.map((item, index) => {
            const { title, description, category, image, imageUrl, price, newPrice } = item;
            return (
              <tr key={index} className="bg-gray-50 border-b  dark:border-gray-700">
                <td className="px-6 py-4 text-black">{allorder.paymentId}</td>
                <td className="px-6 py-4 font-medium text-black whitespace-nowrap">
                  <img className="w-16" src={imageUrl || image} alt="img" />
                </td>
                <td className="px-6 py-4 text-black">{title}</td>
                <td className="px-6 py-4 text-black">₹{newPrice || price}</td>
                <td className="px-6 py-4 text-black">{category}</td>
                <td className="px-6 py-4 text-black">{allorder.addressInfo.name}</td>
                <td className="px-6 py-4 text-black">{allorder.addressInfo.address}</td>
                <td className="px-6 py-4 text-black">{allorder.addressInfo.pincode}</td>
                <td className="px-6 py-4 text-black">{allorder.addressInfo.phoneNumber}</td>
                <td className="px-6 py-4 text-black">{allorder.date}</td>
              </tr>
            );
          });
        })}
      </tbody>
    </table>
  </div>
</TabPanel>


            {/* message details */}

            <TabPanel>
              <div className="relative overflow-x-auto mb-10 my-20">
                <table className="w-full text-sm text-left text-black ">
                  <thead className="text-xs text-black uppercase  ">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        S.No
                      </th>

                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Phone Number
                      </th>
                      <th scope="col" className="px-6 py-3">
                        E-mail
                      </th>

                      <th scope="col" className="px-6 py-3">
                        Message
                      </th>
                 
                    </tr>
                  </thead>

                  {message.map((item, index) => {
                    const { name, phoneno, email, comment } = item;
                    return (
                      <tbody>
                        <tr className=" border-b  ">
                          <td className="px-6 py-4 text-black ">
                            {index + 1}.
                          </td>
                          <td className="px-6 py-4 text-black ">{name}</td>
                          <td className="px-6 py-4 text-black ">{email}</td>
                          <td className="px-6 py-4 text-black ">{phoneno}</td>
                          <td className="px-6 py-4 text-black ">{comment}</td>
                     

                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default DashboardTab;
